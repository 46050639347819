export const BRANCHES_LIST_REQUEST = 'COMMON/BRANCHES_LIST_REQUEST'
export const BRANCHES_LIST_SUCCESS = 'COMMON/BRANCHES_LIST_SUCCESS'

export function getBranchesList() {
  return dispatch => {
    dispatch({
      types: [BRANCHES_LIST_REQUEST, BRANCHES_LIST_SUCCESS],
      payload: {
        request: {
          method: 'GET',
          url: '/base/get-branches-list',
          params: {},
        },
      },
    })
  }
}

export const PLACES_LIST_REQUEST = 'COMMON/PLACES_LIST_REQUEST'
export const PLACES_LIST_SUCCESS = 'COMMON/PLACES_LIST_SUCCESS'

export function getPlacesList() {
  return dispatch => {
    dispatch({
      types: [PLACES_LIST_REQUEST, PLACES_LIST_SUCCESS],
      payload: {
        request: {
          method: 'GET',
          url: '/base/get-places-list',
          params: {},
        },
      },
    })
  }
}

export const EVENTS_LIST_REQUEST = 'COMMON/EVENTS_LIST_REQUEST'
export const EVENTS_LIST_SUCCESS = 'COMMON/EVENTS_LIST_SUCCESS'

export function getEventsList() {
  return dispatch => {
    return dispatch({
      types: [EVENTS_LIST_REQUEST, EVENTS_LIST_SUCCESS],
      payload: {
        request: {
          method: 'GET',
          url: '/base/get-events-list',
          params: {},
        },
      },
    })
  }
}

export const EXCURSIONS_LIST_REQUEST = 'COMMON/EXCURSIONS_LIST_REQUEST'
export const EXCURSIONS_LIST_SUCCESS = 'COMMON/EXCURSIONS_LIST_SUCCESS'

export function getExcursionsList() {
  return dispatch => {
    return dispatch({
      types: [EXCURSIONS_LIST_REQUEST, EXCURSIONS_LIST_SUCCESS],
      payload: {
        request: {
          method: 'GET',
          url: '/base/get-excursions-list',
          params: {},
        },
      },
    })
  }
}

export const CATEGORIES_LIST_REQUEST = 'COMMON/CATEGORIES_LIST_REQUEST'
export const CATEGORIES_LIST_SUCCESS = 'COMMON/CATEGORIES_LIST_SUCCESS'

export function getCategoriesList() {
  return dispatch => {
    dispatch({
      types: [CATEGORIES_LIST_REQUEST, CATEGORIES_LIST_SUCCESS],
      payload: {
        request: {
          method: 'GET',
          url: '/base/get-categories-list',
          params: {},
        },
      },
    })
  }
}

export const TYPES_LIST_REQUEST = 'COMMON/TYPES_LIST_REQUEST'
export const TYPES_LIST_SUCCESS = 'COMMON/TYPES_LIST_SUCCESS'

export function getTypesList() {
  return dispatch => {
    dispatch({
      types: [TYPES_LIST_REQUEST, TYPES_LIST_SUCCESS],
      payload: {
        request: {
          method: 'GET',
          url: '/base/get-types-list',
          params: {},
        },
      },
    })
  }
}

export const EVENT_TYPES_LIST_REQUEST = 'COMMON/EVENT_TYPES_LIST_REQUEST'
export const EVENT_TYPES_LIST_SUCCESS = 'COMMON/EVENT_TYPES_LIST_SUCCESS'

export function getEventTypesList() {
  return dispatch => {
    dispatch({
      types: [EVENT_TYPES_LIST_REQUEST, EVENT_TYPES_LIST_SUCCESS],
      payload: {
        request: {
          method: 'GET',
          url: '/base/get-event-types-list',
          params: {},
        },
      },
    })
  }
}