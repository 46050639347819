import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import {
  TimePicker,
  InputNumber,
  Button,
  Modal,
  Switch,
  Row,
  Col,
  Select,
  Card,
  Icon,
  Popconfirm
} from 'antd'
import moment from 'moment'
import uuid from 'uuid'

import {
  updateSeancesSettings,
} from '../../../../redux/actions/TicketsActions'

import { getWeekDayNameByCode } from '../../../../utils/getWeekdayName'

const { Option } = Select

class RegularSeancesTable extends PureComponent {
  state = {
    isOpenModal: false,
    seances_settings: null,
  }

  renderItem(key, data) {
    const colParams = {
      title: 8,
      value: 16
    }

    let daySettings = null

    if (data.type === 'fullday' || data.type === 'interval') {
      daySettings = (
        <Row className="RegularSeancesTableComponent__day-settings">
          <Col span={colParams.title}>
            Билетов в продаже:
          </Col>
          <Col span={colParams.value}>
            {data.settings.sales_limit} шт
          </Col>
          <Col span={colParams.title}>
            Посещение:
          </Col>
          <Col span={colParams.value}>
            с {data.settings.time_s} до {data.settings.time_e}
          </Col>
          {data.type === 'interval' && (
            <>
              <Col span={colParams.title}>
                Интервал:
              </Col>
              <Col span={colParams.value}>
                {data.settings.interval} мин
              </Col>
            </>
          )}
        </Row>
      )
    }

    if (data.type === 'schedule') {
      daySettings = (
        <Row className="RegularSeancesTableComponent__day-settings">
          <Col span={colParams.title}>
            Сеансы:
          </Col>
          <Col span={colParams.value}>
            <ul className="RegularSeancesTableComponent__schedule-seances-list">
              {data.settings
                .sort((a, b) => {
                  return moment(a.time_s, 'HH:mm') > moment(b.time_s, 'HH:mm') ? 1 : -1
                })
                .map(item => (
                  <li key={item.uuid}>
                    с {item.time_s} до {item.time_e}, {item.sales_limit} шт
                  </li>
                ))}
            </ul>
          </Col>
        </Row>
      )
    }

    let typeName = ''

    switch (data.type) {
      case 'fullday':
        typeName = 'полный день'
        break
      case 'interval':
        typeName = 'интервал'
        break
      case 'schedule':
        typeName = 'произвольный'
        break
      default:
        break
    }

    return (
      <div
        style={{ opacity: data.is_active ? 1 : 0.5 }}
        className="RegularSeancesTableComponent__weekday-item"
        key={key}
      >
        <b>{getWeekDayNameByCode(key)}</b><br />
        {
          data.is_active ? (
            <>
              <Row>
                <Col span={colParams.title}>
                  Тип:
                </Col>
                <Col span={colParams.value}>
                  {typeName}
                </Col>
              </Row>
              {daySettings}
            </>
          ) :
            <span>Неактивен</span>
        }
      </div>
    )
  }

  setSettingsValue(key, field, value, callback = () => { }) {
    const { seances_settings } = this.state

    this.setState({
      seances_settings: {
        ...seances_settings,
        [key]: {
          ...seances_settings[key],
          settings: {
            ...seances_settings[key].settings,
            [field]: value
          }
        }
      }
    }, callback)
  }

  setScheduleDaySettings(key, uuid, field, value, callback = () => { }) {
    const { seances_settings } = this.state

    const settingsIndex = seances_settings[key].settings.findIndex(item => item.uuid === uuid)

    let settingsCopy = seances_settings[key].settings.slice()

    settingsCopy[settingsIndex] = {
      ...settingsCopy[settingsIndex],
      [field]: value,
    }

    this.setState({
      seances_settings: {
        ...seances_settings,
        [key]: {
          ...seances_settings[key],
          settings: settingsCopy
        }
      }
    }, callback)
  }

  renderScheduleDayItem(key, item) {
    const { seances_settings } = this.state

    const colParams = {
      title: 8,
      value: 16
    }

    return (
      <Card
        key={item.uuid}
        className="RegularSeancesTableComponent__weekday-settings-card"
        actions={[
          (
            <Popconfirm
              title="Вы уверены, что хотите удалить запись?"
              onConfirm={() => {
                const settingsIndex = seances_settings[key].settings.findIndex(
                  settingsItem => settingsItem.uuid === item.uuid
                )
                let settingsCopy = seances_settings[key].settings.slice()
                settingsCopy.splice(settingsIndex, 1)
                this.setState({
                  seances_settings: {
                    ...seances_settings,
                    [key]: {
                      ...seances_settings[key],
                      settings: settingsCopy
                    }
                  }
                })
              }}
              okText="Да"
              cancelText="Нет"
            >
              <Icon type="delete" />
            </Popconfirm>
          ),
        ]}
        size="small"
      >
        <Row
          className="RegularSeancesTableComponent__weekday-settings-row"
          type="flex"
          align="middle"
        >
          <Col span={colParams.title}>
            В продаже:
          </Col>
          <Col span={colParams.value}>
            <InputNumber
              min={1}
              max={10000}
              onChange={(value) => this.setScheduleDaySettings(key, item.uuid, 'sales_limit', value)}
              value={item.sales_limit}
              style={{ width: '128px' }}
            />
          </Col>
        </Row>
        <Row
          className="RegularSeancesTableComponent__weekday-settings-row"
          type="flex"
          align="middle"
        >
          <Col span={colParams.title}>
            Начало:
          </Col>
          <Col span={colParams.value}>
            <TimePicker
              format="HH:mm"
              onChange={(date, timeStr) => {
                this.setScheduleDaySettings(
                  key,
                  item.uuid,
                  'time_s',
                  timeStr,
                  () => {
                    if (date > moment(item.time_e, 'HH:mm')) {
                      this.setScheduleDaySettings(
                        key,
                        item.uuid,
                        'time_e',
                        date.add(1, 'hour').format('HH:mm')
                      )
                    }
                  }
                )

              }}
              value={moment(item.time_s, 'HH:mm')}
            />
          </Col>
        </Row>
        <Row
          className="RegularSeancesTableComponent__weekday-settings-row"
          type="flex"
          align="middle"
        >
          <Col span={colParams.title}>
            Окончание:
          </Col>
          <Col span={colParams.value}>
            <TimePicker
              format="HH:mm"
              onChange={(date, timeStr) => {
                this.setScheduleDaySettings(
                  key,
                  item.uuid,
                  'time_e',
                  timeStr,
                  () => {
                    if (date <= moment(item.time_s, 'HH:mm')) {
                      this.setScheduleDaySettings(
                        key,
                        item.uuid,
                        'time_s',
                        date.add(-1, 'hour').format('HH:mm')
                      )
                    }
                  }
                )

              }}
              value={moment(item.time_e, 'HH:mm')}
            />
          </Col>
        </Row>
      </Card >
    )
  }

  renderSettingsItem(key, data) {
    const { seances_settings } = this.state

    const colParams = {
      title: 8,
      value: 9
    }

    let settingsFields = null

    if (data.type === 'fullday' || data.type === 'interval') {
      settingsFields = (
        <>
          <Row
            className="RegularSeancesTableComponent__weekday-settings-row"
            type="flex"
            align="middle"
          >
            <Col span={colParams.title}>
              Билетов в продаже:
            </Col>
            <Col span={colParams.value}>
              <InputNumber
                min={1}
                max={10000}
                onChange={(value) => this.setSettingsValue(key, 'sales_limit', value)}
                value={data.settings.sales_limit}
                style={{ width: '128px' }}
              />
            </Col>
          </Row>
          <Row
            className="RegularSeancesTableComponent__weekday-settings-row"
            type="flex"
            align="middle"
          >
            <Col span={colParams.title}>
              Начало работы:
            </Col>
            <Col span={colParams.value}>
              <TimePicker
                format="HH:mm"
                onChange={(date, timeStr) => {
                  this.setSettingsValue(
                    key,
                    'time_s',
                    timeStr,
                    () => {
                      if (date > moment(data.settings.time_e, 'HH:mm')) {
                        this.setSettingsValue(
                          key,
                          'time_e',
                          date.add(1, 'hour').format('HH:mm')
                        )
                      }
                    }
                  )

                }}
                value={moment(data.settings.time_s, 'HH:mm')}
              />
            </Col>
          </Row>
          <Row
            className="RegularSeancesTableComponent__weekday-settings-row"
            type="flex"
            align="middle"
          >
            <Col span={colParams.title}>
              Окончание работы:
            </Col>
            <Col span={colParams.value}>
              <TimePicker
                format="HH:mm"
                onChange={(date, timeStr) => {
                  this.setSettingsValue(
                    key,
                    'time_e',
                    timeStr,
                    () => {
                      if (date <= moment(data.settings.time_s, 'HH:mm')) {
                        this.setSettingsValue(
                          key,
                          'time_s',
                          date.add(-1, 'hour').format('HH:mm')
                        )
                      }
                    }
                  )

                }}
                value={moment(data.settings.time_e, 'HH:mm')}
              />
            </Col>
          </Row>
          {data.type === 'interval' && (
            <Row
              className="RegularSeancesTableComponent__weekday-settings-row"
              type="flex"
              align="middle"
            >
              <Col span={colParams.title}>
                Интервал:
              </Col>
              <Col span={colParams.value}>
                <Select
                  style={{ width: '100%' }}
                  value={data.settings.interval}
                  onChange={value => {
                    this.setSettingsValue(key, 'interval', value)
                  }}
                >
                  <Option value={10}>
                    10 мин
                  </Option>
                  <Option value={30}>
                    30 мин
                  </Option>
                  <Option value={60}>
                    60 мин
                  </Option>
                  <Option value={90}>
                    90 мин
                  </Option>
                  <Option value={120}>
                    120 мин
                  </Option>
                </Select>
              </Col>
            </Row>
          )}
        </>
      )
    }

    if (data.type === 'schedule') {
      settingsFields = (
        <>
          <Row
            className="RegularSeancesTableComponent__weekday-settings-row"
          >
            <Col span={colParams.title}>
              Сеансы:
            </Col>
            <Col span={16}>
              {data.settings.map(item => this.renderScheduleDayItem(key, item))}
              <Button
                onClick={() => {
                  let settingsCopy = seances_settings[key].settings.slice()
                  settingsCopy.push({
                    sales_limit: 100,
                    time_s: '12:00',
                    time_e: '13:00',
                    uuid: uuid()
                  })
                  this.setState({
                    seances_settings: {
                      ...seances_settings,
                      [key]: {
                        ...seances_settings[key],
                        settings: settingsCopy,
                      }
                    }
                  })
                }}
              >
                Добавить
              </Button>
            </Col>
          </Row>
        </>
      )
    }

    return (
      <div
        className="RegularSeancesTableComponent__weekday-settings-item"
        key={key}
      >
        <Row className="RegularSeancesTableComponent__weekday-settings-row">
          <Col span={colParams.title}>
            <b style={{ opacity: data.is_active ? 1 : 0.5 }}>
              {getWeekDayNameByCode(key)}
            </b>
          </Col>
          <Col span={colParams.value}>
            <Switch
              checked={data.is_active}
              onChange={value => {
                this.setState({
                  seances_settings: {
                    ...seances_settings,
                    [key]: {
                      is_active: value,
                      type: value ? 'fullday' : '',
                      settings: value ? {
                        sales_limit: 1000,
                        time_s: '10:00',
                        time_e: '18:00',
                      } : null,
                    }
                  }
                })
              }}
            />
          </Col>
        </Row>
        {data.is_active && (
          <Row
            className="RegularSeancesTableComponent__weekday-settings-row"
            type="flex"
            align="middle"
          >
            <Col span={colParams.title}>
              Тип:
            </Col>
            <Col span={colParams.value}>
              <Select
                style={{ width: '100%' }}
                value={data.type}
                onChange={value => {
                  let settings = {}

                  switch (value) {
                    case 'fullday':
                      settings = {
                        sales_limit: 1000,
                        time_s: '10:00',
                        time_e: '18:00'
                      }
                      break
                    case 'interval':
                      settings = {
                        sales_limit: 100,
                        time_s: '10:00',
                        time_e: '18:00',
                        interval: 60
                      }
                      break
                    case 'schedule':
                      settings = []
                      break
                    default:
                      break
                  }

                  this.setState({
                    seances_settings: {
                      ...seances_settings,
                      [key]: {
                        ...seances_settings[key],
                        type: value,
                        settings,
                      }
                    }
                  })
                }}
              >
                <Option value="fullday">
                  Полный день
                </Option>
                <Option value="interval">
                  Интервал
                </Option>
                <Option value="schedule">
                  Произвольный
                </Option>
              </Select>
            </Col>
          </Row>
        )}
        {settingsFields}
      </div>
    )
  }

  render() {
    const ticketForm = this.props.tickets.ticketForm
    const { seances_settings } = this.state

    return (
      <>
        {Object.keys(ticketForm.seances_settings)
          .map(dayKey =>
            this.renderItem(dayKey, ticketForm.seances_settings[dayKey])
          )}
        <Button
          className="RegularSeancesTableComponent__settings-button"
          disabled={this.props.disabled}
          onClick={
            () => this.setState({
              isOpenModal: true,
              seances_settings: ticketForm.seances_settings
            })
          }
        >
          Настроить расписание
        </Button>
        <Modal
          visible={this.state.isOpenModal}
          okText="Сохранить"
          cancelText="Закрыть"
          onOk={() => {
            this.props.updateSeancesSettings(seances_settings)
            this.setState({ isOpenModal: false })
          }}
          onCancel={() => this.setState({ isOpenModal: false })}
        >
          {seances_settings && Object.keys(seances_settings)
            .map(dayKey =>
              this.renderSettingsItem(dayKey, seances_settings[dayKey])
            )}
        </Modal>
      </>
    )
  }
}

const mapStateToProps = store => {
  return {
    tickets: store.tickets,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateSeancesSettings: (seancesSettings) =>
      dispatch(updateSeancesSettings(seancesSettings)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegularSeancesTable)