export const ORDERS_LOAD_REQUEST = 'ORDERS/ORDERS_LOAD_REQUEST'
export const ORDERS_LOAD_SUCCESS = 'ORDERS/ORDERS_LOAD_SUCCESS'

export function getOrdersList(pageNumber = 1, sort = null, filterForm = null) {
  return dispatch => {
    let additionalParams = {}

    if (sort) {
      additionalParams.sort = sort.field
      additionalParams.sort_type = sort.type
    }

    if (filterForm) {
      additionalParams.id = filterForm.id
      additionalParams.is_approved = filterForm.is_approved
      additionalParams.status = filterForm.status
    }

    dispatch({
      types: [ORDERS_LOAD_REQUEST, ORDERS_LOAD_SUCCESS],
      payload: {
        request: {
          method: 'GET',
          url: '/order/get-orders-list',
          params: {
            page: pageNumber,
            ...additionalParams,
          },
        },
      },
    })
  }
}

export const ORDER_LOAD_REQUEST = 'ORDERS/ORDER_LOAD_REQUEST'
export const ORDER_LOAD_SUCCESS = 'ORDERS/ORDER_LOAD_SUCCESS'

export function getOrder(id) {
  return dispatch => {
    return dispatch({
      types: [ORDER_LOAD_REQUEST, ORDER_LOAD_SUCCESS],
      payload: {
        request: {
          method: 'GET',
          url: `/order/get/${id}`,
        },
      },
    })
  }
}

export const TOGGLE_ORDER_FORM = 'ORDERS/TOGGLE_ORDER_FORM'

export function toggleOrderForm(isOpen = true) {
  return dispatch => {
    dispatch({
      type: TOGGLE_ORDER_FORM,
      payload: {
        isOpen,
      }
    })
  }
}

export const SET_FIELD_FOR_ORDER = 'ORDERS/SET_FIELD_FOR_ORDER'

export function setFieldForOrder(field, value) {
  return dispatch => {
    dispatch({
      type: SET_FIELD_FOR_ORDER,
      payload: {
        field,
        value,
      },
    })
  }
}

export const APPROVE_ORDER_REQUEST = 'ORDERS/APPROVE_ORDER_REQUEST'
export const APPROVE_ORDER_SUCCESS = 'ORDERS/APPROVE_ORDER_SUCCESS'

export function approveOrder(id, visitationDate) {
  return dispatch => {
    dispatch({
      types: [APPROVE_ORDER_REQUEST, APPROVE_ORDER_SUCCESS],
      payload: {
        request: {
          method: 'POST',
          url: `/order/approve/${id}`,
          data: {
            visitation_date: visitationDate,
          },
        },
      },
    })
  }
}

export const UPDATE_ORDER_REQUEST = 'ORDERS/UPDATE_ORDER_REQUEST'
export const UPDATE_ORDER_SUCCESS = 'ORDERS/UPDATE_ORDER_SUCCESS'

export function updateOrder(id, formObj) {
  return dispatch => {
    dispatch({
      types: [APPROVE_ORDER_REQUEST, APPROVE_ORDER_SUCCESS],
      payload: {
        request: {
          method: 'POST',
          url: `/order/update/${id}`,
          data: {
            ...formObj,
          },
        },
      },
    })
  }
}

export const CANCEL_ORDER_REQUEST = 'ORDERS/CANCEL_ORDER_REQUEST'
export const CANCEL_ORDER_SUCCESS = 'ORDERS/CANCEL_ORDER_SUCCESS'

export function cancelOrder(id) {
  return dispatch => {
    dispatch({
      types: [CANCEL_ORDER_REQUEST, CANCEL_ORDER_SUCCESS],
      payload: {
        request: {
          method: 'POST',
          url: `/order/cancel/${id}`,
        },
      },
    })
  }
}