import UserAPI from '../../api/UserAPI'

export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_NOT_FOUND = 'USER_LOGIN_NOT_FOUND'
export const USER_LOGIN_SERVER_ERROR = 'USER_LOGIN_SERVER_ERROR'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGOUT = 'USER_LOGOUT'

export function logInUser(login, password) {
  return dispatch => {
    dispatch({
      type: USER_LOGIN_REQUEST,
    })

    UserAPI.logIn(login, password)
      .then(res => {
        dispatch({
          type: USER_LOGIN_SUCCESS,
          payload: res.data,
        })
      })
      .catch(e => {
        switch (e.response.status) {
          case 404:
            dispatch({
              type: USER_LOGIN_NOT_FOUND,
            })
            break
          default:
            dispatch({
              type: USER_LOGIN_SERVER_ERROR,
            })
            break
        }
      })
  }
}

export function logOutUser() {
  return dispatch => {
    dispatch({
      type: USER_LOGOUT,
    })
  }
}

export const USER_ACCESS_SETTINGS_REQUEST = 'USER_ACCESS_SETTINGS_REQUEST'
export const USER_ACCESS_SETTINGS_SUCCESS = 'USER_ACCESS_SETTINGS_SUCCESS'

export function getUserAccessSettings() {
  return dispatch => {
    dispatch({
      types: [USER_ACCESS_SETTINGS_REQUEST, USER_ACCESS_SETTINGS_SUCCESS],
      payload: {
        request: {
          method: 'GET',
          url: '/users/get-access-settings',
          params: {},
        },
      },
    })
  }
}

export const USERS_LIST_REQUEST = 'USERS_LIST_REQUEST'
export const USERS_LIST_SUCCESS = 'USERS_LIST_SUCCESS'

export function getUsersList() {
  return dispatch => {
    dispatch({
      types: [USERS_LIST_REQUEST, USERS_LIST_SUCCESS],
      payload: {
        request: {
          method: 'GET',
          url: '/users/get-users-list',
          params: {},
        },
      },
    })
  }
}
