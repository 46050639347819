import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import {
  Form,
  Button,
  Switch,
  Icon,
  Spin,
  Select,
} from 'antd'

import {
  setFieldForCertificate,
  createCertificate,
  updateCertificate,
} from '../../../../redux/actions/CertificatesActions'

import hasUserAccess from '../../../../utils/hasUserAccess'

const { Option } = Select

class CertificateForm extends PureComponent {

  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { certificateForm } = this.props.certificates
        let formObj = {}

        Object.keys(certificateForm).forEach(item => {
          if (
            certificateForm[item] !== null &&
            item !== 'id' &&
            item !== 'isOpen' &&
            item !== 'isSending'
          ) {
            formObj[item] = certificateForm[item]
          }
        })

        if (!certificateForm.id) {
          this.props.createCertificate(formObj)
        } else {
          this.props.updateCertificate(certificateForm.id, formObj)
        }
      }
    })
  }

  render() {
    const antIcon = <Icon type="loading" style={{ fontSize: 40 }} spin />

    const { getFieldDecorator } = this.props.form
    const { user } = this.props

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    }

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    }

    const certificateForm = this.props.certificates.certificateForm
    const isDisabled = certificateForm.booked_certificates > 0
    const disableAll = !hasUserAccess(user.accessSettings, 'update_certificates')

    return certificateForm.isOpen ? (
      <Spin spinning={certificateForm.isSending} indicator={antIcon}>
        {certificateForm.id && certificateForm.booked_certificates > 0 && (
          <p>
            Для сертификатов, которые уже были заказаны, некоторые поля недоступны
            для редактирования.
            <br />
            Для текущего сертификата <strong>заказано: </strong>{' '}
            {certificateForm.booked_certificates}, <strong>оплачено: </strong>{' '}
            {certificateForm.certificates_sold}
          </p>
        )}
        <Form {...formItemLayout} onSubmit={this.handleSubmit}>

          <Form.Item label="Цена">
            {getFieldDecorator('price', {
              rules: [{ required: true, message: 'Выберите' }],
              initialValue: certificateForm.price,
            })(
              <Select
                placeholder="Выберите"
                filterOption={false}
                disabled={isDisabled || disableAll}
                onChange={value =>
                  this.props.setFieldForCertificate('price', value)
                }
              >
                <Option value={500}>500</Option>
                <Option value={1000}>1000</Option>
                <Option value={2000}>2000</Option>
                <Option value={3000}>3000</Option>
                <Option value={5000}>5000</Option>
              </Select>
            )}
          </Form.Item>

          <Form.Item label="Доступно к продаже">
            <Switch
              checked={certificateForm.is_available_for_sale === 1 ? true : false}
              disabled={disableAll}
              onChange={value =>
                this.props.setFieldForCertificate(
                  'is_available_for_sale',
                  value ? 1 : 0
                )
              }
            />
          </Form.Item>

          {!disableAll && (
            <Form.Item {...tailFormItemLayout}>
              <Button type="primary" htmlType="submit">
                Сохранить
              </Button>
            </Form.Item>
          )}
        </Form>
      </Spin>
    ) : null
  }
}

const mapStateToProps = store => {
  return {
    certificates: store.certificates,
    user: store.user,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setFieldForCertificate: (field, value) =>
      dispatch(setFieldForCertificate(field, value)),
    createCertificate: formObj => dispatch(createCertificate(formObj)),
    updateCertificate: (id, formObj) => dispatch(updateCertificate(id, formObj)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: 'CertificateForm' })(CertificateForm))