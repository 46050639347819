import { message } from 'antd'
import moment from 'moment'

const TYPES_TO_MIMES = {
  xls: ['application/vnd.ms-excel'],
  xlsx: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
  doc: ['application/msword'],
  docx: [
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ],
  pdf: ['application/pdf'],
  ppt: ['application/vnd.ms-powerpoint'],
  pptx: [
    'application/vnd.openxmlformats-officedocument.presentationml.presentation'
  ],
  jpg: ['image/jpeg']
}

export function checkFileType(file, allowTypes) {
  let isAllow = false
  if (allowTypes) {
    allowTypes.forEach(type => {
      if (TYPES_TO_MIMES[type].indexOf(file.type) !== -1) {
        isAllow = true
      }
    })
  } else {
    isAllow = true
  }
  if (!isAllow) {
    message.error(`${file.name} неверный формат файла`)
  }
  return isAllow
}

export function getFilePath(createdDate, hash) {
  let momentDate = moment(createdDate, 'YYYY-MM-DD HH:mm')

  return `${process.env.REACT_APP_API_HOST.replace('/api', '')}/uploads/${momentDate.format('YYYY/MM/DD')}/${hash}`
}