import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import {
  message,
  Upload,
  Modal
} from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import { setFieldForTicket } from '../../../../redux/actions/TicketsActions'

import {
  checkFileType,
  getFilePath
} from '../../../../utils/uploadingFile'

class UploadImage extends PureComponent {
  state = {
    isOpenModal: false,
    file: null,
  }

  render() {
    const ticketForm = this.props.tickets.ticketForm

    let fileList = []
    if (
      ticketForm.image.image_id ||
      ticketForm.image.image_uid
    ) {
      fileList.push({
        name: ticketForm.image.image_name,
        uid: ticketForm.image.image_uid ?
          ticketForm.image.image_uid :
          ticketForm.image.image_id,
        url: ticketForm.image.image_hash ?
          getFilePath(ticketForm.image.image_date_creating, ticketForm.image.image_hash) : '',
        status: ticketForm.image.image_hash ? 'done' : 'uploading'
      })
    }

    const self = this
    const props = {
      name: 'file',
      action: `${process.env.REACT_APP_API_HOST}/file/add`,
      headers: {
        authorization: 'Bearer ' + this.props.user.attributes.token
      },
      listType: 'picture-card',
      beforeUpload: file => checkFileType(file, ['jpg']),
      fileList,
      onPreview: file => {
        self.setState({ isOpenModal: true, file })
      },
      onChange: info => {
        if (info.fileList.length === 0) {
          self.props.setFieldForTicket('image_id', 0)
          self.props.setFieldForTicket('image', {
            image_id: null,
            image_name: null,
            image_hash: null,
            image_date_creating: null
          })
          return
        }

        if (info.file.status === 'done') {
          self.props.setFieldForTicket('image', {
            status: 'done',
            image_id: info.file.response.data.id,
            image_name: info.file.response.data.name,
            image_hash: info.file.response.data.hash,
            image_date_creating: info.file.response.data.date_creating,
            url: getFilePath(
              info.file.response.data.date_creating,
              info.file.response.data.hash
            ),
          })
          self.props.setFieldForTicket('image_id', info.file.response.data.id)
        } else {
          self.props.setFieldForTicket('image', {
            image_uid: info.fileList[0].uid,
            image_name: info.fileList[0].name,
            ...info.fileList[0]
          })

          if (info.file.error) {
            if (info.file.error.status === 415) {
              message.error(`${info.file.name} неверный формат файла`)
            } else if (info.file.error.status === 413) {
              message.error(`${info.file.name} превышен размер файла`)
            } else {
              message.error(
                `${info.file.name} загрузка прервана. Код ошибки ${info.file.error.status}`
              )
            }
          }
        }
      }
    }

    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Загрузить</div>
      </div>
    )

    return (
      <>
        <Upload {...props} disabled={this.props.disabled}>
          {fileList.length === 0 ? uploadButton : null}
        </Upload>
        <Modal
          visible={this.state.isOpenModal}
          footer={null}
          onCancel={() => this.setState({ isOpenModal: false })}
          title="Просмотр"
        >
          {this.state.file && (
            <img src={this.state.file.url} style={{ width: '100%' }} alt={this.state.file.name} />
          )}
        </Modal>
      </>
    )
  }
}

const mapStateToProps = store => {
  return {
    tickets: store.tickets,
    user: store.user,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setFieldForTicket: (field, value) =>
      dispatch(setFieldForTicket(field, value)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadImage)