import produce from 'immer'

import {
  BRANCHES_LIST_REQUEST,
  BRANCHES_LIST_SUCCESS,
  PLACES_LIST_REQUEST,
  PLACES_LIST_SUCCESS,
  EVENTS_LIST_REQUEST,
  EVENTS_LIST_SUCCESS,
  CATEGORIES_LIST_REQUEST,
  CATEGORIES_LIST_SUCCESS,
  TYPES_LIST_REQUEST,
  TYPES_LIST_SUCCESS,
  EVENT_TYPES_LIST_REQUEST,
  EVENT_TYPES_LIST_SUCCESS,
  EXCURSIONS_LIST_REQUEST,
  EXCURSIONS_LIST_SUCCESS,
} from '../actions/CommonActions'

const initialState = {
  isLoadingBranches: false,
  branches: [],
  isLoadingPlaces: false,
  places: [],
  isLoadingEvents: false,
  events: [],
  isLoadingCategories: false,
  categories: [],
  isLoadingTypes: false,
  types: [],
  isLoadingEventTypes: false,
  event_types: [],
  isLoadingExcursions: false,
  excursions: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
    case BRANCHES_LIST_REQUEST:
      return produce(state, draftState => {
        draftState.isLoadingBranches = true
      })
    case BRANCHES_LIST_SUCCESS:
      return produce(state, draftState => {
        draftState.isLoadingBranches = false
        draftState.branches = action.payload.data
      })
    case PLACES_LIST_REQUEST:
      return produce(state, draftState => {
        draftState.isLoadingPlaces = true
      })
    case PLACES_LIST_SUCCESS:
      return produce(state, draftState => {
        draftState.isLoadingPlaces = false
        draftState.places = action.payload.data
      })
    case EVENTS_LIST_REQUEST:
      return produce(state, draftState => {
        draftState.isLoadingEvents = true
      })
    case EVENTS_LIST_SUCCESS:
      return produce(state, draftState => {
        draftState.isLoadingEvents = false
        draftState.events = action.payload.data
      })
    case CATEGORIES_LIST_REQUEST:
      return produce(state, draftState => {
        draftState.isLoadingCategories = true
      })
    case CATEGORIES_LIST_SUCCESS:
      return produce(state, draftState => {
        draftState.isLoadingCategories = false
        draftState.categories = action.payload.data
      })
    case TYPES_LIST_REQUEST:
      return produce(state, draftState => {
        draftState.isLoadingTypes = true
      })
    case TYPES_LIST_SUCCESS:
      return produce(state, draftState => {
        draftState.isLoadingTypes = false
        draftState.types = action.payload.data
      })
    case EVENT_TYPES_LIST_REQUEST:
      return produce(state, draftState => {
        draftState.isLoadingEventTypes = true
      })
    case EVENT_TYPES_LIST_SUCCESS:
      return produce(state, draftState => {
        draftState.isLoadingEventTypes = false
        draftState.event_types = action.payload.data
      })
    case EXCURSIONS_LIST_REQUEST:
      return produce(state, draftState => {
        draftState.isLoadingExcursions = true
      })
    case EXCURSIONS_LIST_SUCCESS:
      return produce(state, draftState => {
        draftState.isLoadingExcursions = false
        draftState.excursions = action.payload.data
      })
    default:
      return state
  }
}
