import React, { PureComponent } from 'react'
import {
  Row,
  Col,
  Icon,
  Table,
  Radio,
  Spin,
  Drawer,
  InputNumber,
  Switch,
} from 'antd'
import { Helmet } from 'react-helmet'
import moment from 'moment'
import { connect } from 'react-redux'
import { TweenOneGroup } from 'rc-tween-one'
import { EditOutlined } from '@ant-design/icons'

import { getStatus } from '../../../utils/orderFunc'
import hasUserAccess from '../../../utils/hasUserAccess'

import {
  getOrdersList,
  toggleOrderForm,
  getOrder,
} from '../../../redux/actions/OrdersActions'

import OrderForm from './Components/OrderFormComponent'

const TableContext = React.createContext(false)

const enterAnim = [
  {
    opacity: 0,
    x: 30,
    backgroundColor: '#fffeee',
    duration: 0,
  },
  {
    height: 0,
    duration: 200,
    type: 'from',
    delay: 250,
    ease: 'easeOutQuad',
  },
  {
    opacity: 1,
    x: 0,
    duration: 250,
    ease: 'easeOutQuad',
  },
  { delay: 1000, backgroundColor: '#fff' },
]

const leaveAnim = [
  { duration: 250, opacity: 0 },
  { height: 0, duration: 200, ease: 'easeOutQuad' },
]

class Orders extends PureComponent {
  animTag = $props => {
    return (
      <TableContext.Consumer>
        {() => {
          return (
            <TweenOneGroup
              component="tbody"
              enter={this.props.orders.animation ? enterAnim : null}
              leave={this.props.orders.animation ? leaveAnim : null}
              appear={false}
              exclusive
              {...$props}
            />
          )
        }}
      </TableContext.Consumer>
    )
  }

  handleTableChange = (pagination, filters, sorter) => {
    let sort = null
    if (Object.keys(sorter).length > 0) {
      sort = {
        field: sorter.field,
        type: sorter.order === 'ascend' ? 'asc' : 'desc',
      }
    }
    this.props.getOrdersList(1, sort, {
      ...this.props.orders.filter,
    })
  }

  handleScroll = e => {
    if (
      this.props.orders.isLoadingOrders ||
      this.props.orders.isTotalReached
    ) {
      return
    }

    const body = document.body,
      html = document.documentElement

    const wholePageHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    )

    const scrollTop = window.scrollY
    const windowHeight = window.innerHeight

    if (scrollTop > wholePageHeight - windowHeight * 1.05) {
      const pageNumber = this.props.orders.pageNumber + 1
      this.props.getOrdersList(
        pageNumber,
        this.props.orders.sort,
        this.props.orders.filter
      )
    }
  }

  componentDidMount() {
    this.props.getOrdersList(1, this.props.orders.sort, this.props.orders.filter)
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  openEditOrderForm(id) {
    this.props.toggleOrderForm(true)
    this.props.getOrder(id)
  }

  render() {
    const antIcon = <Icon type="loading" style={{ fontSize: 40 }} spin />

    const { user } = this.props

    const data = this.props.orders.orders.map(item => ({
      key: item.id,
      id: item.id,
      parent_ticket_name: item.parent_ticket.parent_ticket_title,
      status: getStatus(item.status),
      created_date: moment(item.created_date, 'YYYY-MM-DD HH:mm').format(
        'DD.MM.YYYY HH:mm'
      ),
      is_approved: item.is_approved === 1 ? 'да' : 'нет',
    }))

    const columns = [
      {
        title: '№',
        dataIndex: 'id',
        className: 'Activity__table-cell',
        sorter: true,
        onCell: (record, rowIndex) => ({
          onClick: event => {
            this.openEditOrderForm(record.id)
          },
        }),
      },
      {
        title: 'Билет',
        dataIndex: 'parent_ticket_name',
        className: 'Activity__table-cell',
        onCell: (record, rowIndex) => ({
          onClick: event => {
            this.openEditOrderForm(record.id)
          },
        }),
      },
      {
        title: 'Дата',
        dataIndex: 'created_date',
        sorter: true,
        className: 'Activity__table-cell',
        onCell: (record, rowIndex) => ({
          onClick: event => {
            this.openEditOrderForm(record.id)
          },
        }),
      },
      {
        title: 'Статус',
        dataIndex: 'status',
        sorter: true,
        className: 'Activity__table-cell',
        onCell: (record, rowIndex) => ({
          onClick: event => {
            this.openEditOrderForm(record.id)
          },
        }),
      },
      {
        title: 'Подтвержден',
        dataIndex: 'is_approved',
        sorter: true,
        className: 'Activity__table-cell',
        onCell: (record, rowIndex) => ({
          onClick: event => {
            this.openEditOrderForm(record.id)
          },
        }),
      },

      {
        title: '',
        key: 'action',
        width: 50,
        render: (text, record) =>
          hasUserAccess(user.accessSettings, 'update_orders') ? (
            <span className="Activity__control-buttons">
              <a
                href="/"
                onClick={e => {
                  e.preventDefault()
                  this.openEditOrderForm(record.id)
                }}
              >
                <EditOutlined />
              </a>
            </span>
          ) : null,
      },
    ]

    let additionalTableParams = {}
    if (this.props.orders.animation) {
      additionalTableParams.components = { body: { wrapper: this.animTag } }
    }

    let tableStyle = null
    if (
      this.props.orders.isLoadingTickets &&
      this.props.orders.pageNumber === 1
    ) {
      tableStyle = { display: 'none' }
    }

    let searchTimer

    return (
      <div>
        <Helmet>
          <title>Заказы</title>
        </Helmet>

        <h1>
          Заказы
        </h1>

        <Row gutter={16} className="Activity__settings">
          <Col span={9}>
            <Radio.Group
              onChange={e => {
                const value = e.target.value
                this.props.getOrdersList(1, this.props.orders.sort, {
                  ...this.props.orders.filter,
                  is_approved: value,
                })
              }}
              value={this.props.orders.filter.is_approved}
            >
              <Radio.Button value={'all'}>Все</Radio.Button>
              <Radio.Button value={'1'}>Подтвержденные</Radio.Button>
              <Radio.Button value={'0'}>Неподтвержденные</Radio.Button>
            </Radio.Group>
          </Col>
          <Col span={7}>
            <InputNumber
              min={1}
              style={{ width: '100%' }}
              placeholder="Номер заказа"
              onChange={value => {
                if (value === this.props.orders.filter.id) return

                clearTimeout(searchTimer)
                searchTimer = setTimeout(() => {
                  this.props.getOrdersList(1, this.props.orders.sort, {
                    ...this.props.orders.filter,
                    id: value,
                  })
                }, 500)
              }}
            />
          </Col>
          <Col span={7}>
            <Switch
              checked={!!this.props.orders.filter.status}
              onChange={value => {
                this.props.getOrdersList(1, this.props.orders.sort, {
                  ...this.props.orders.filter,
                  status: value ? 'ACTIVE' : undefined,
                })
              }}
              style={{ marginRight: '0.5rem' }}
            />
            ожидает оплаты
          </Col>
        </Row>

        <TableContext.Provider>
          <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            onChange={this.handleTableChange}
            style={tableStyle}
            {...additionalTableParams}
          />
        </TableContext.Provider>

        {this.props.orders.isLoadingOrders && (
          <div className="Activity__loader">
            <Spin indicator={antIcon} />
          </div>
        )}

        <Drawer
          title={`Заказ №${this.props.orders.orderForm.data.id || ''}`}
          width={'700'}
          onClose={() => this.props.toggleOrderForm(false)}
          visible={this.props.orders.orderForm.isOpen}
        >
          <OrderForm />
        </Drawer>
      </div>
    )
  }
}

const mapStateToProps = store => {
  return {
    orders: store.orders,
    user: store.user,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getOrdersList: (pageNumber = 1, sort = null, filterForm = null) =>
      dispatch(getOrdersList(pageNumber, sort, filterForm)),
    toggleOrderForm: isOpen => dispatch(toggleOrderForm(isOpen)),
    getOrder: id => dispatch(getOrder(id)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Orders)