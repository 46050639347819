import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import {
  Form,
  Select,
  DatePicker,
  InputNumber,
  Input,
  Button,
  Switch,
  Icon,
  Spin,
  Table,
} from 'antd'
import moment from 'moment'

import {
  setFieldForSubscription,
  updateCategories,
  setPriceForCategory,
  updatePlaces,
  setNumVisitsForPlace,
  createSubscription,
  updateSubscription,
} from '../../../../redux/actions/SubscriptionsActions'
import {
  getPlacesList,
  getCategoriesList,
} from '../../../../redux/actions/CommonActions'
import makeAccessCode from '../../../../utils/makeAccessCode'
import hasUserAccess from '../../../../utils/hasUserAccess'

const { Option } = Select

class SubscriptionForm extends PureComponent {
  componentDidMount = () => {
    this.props.getPlacesList()
    this.props.getCategoriesList()
  }

  getCategoryNameById = id => {
    let name = ''
    this.props.common.categories.forEach(item => {
      if (item.id === id) {
        name = item.name
      }
    })
    return name
  }

  getPlaceNameById = id => {
    let name = ''
    this.props.common.places.forEach(item => {
      if (item.id === id) {
        name = item.name
      }
    })
    return name
  }

  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { subscriptionForm } = this.props.subscriptions
        let formObj = {}

        Object.keys(subscriptionForm).forEach(item => {
          if (
            subscriptionForm[item] !== null &&
            item !== 'id' &&
            item !== 'isOpen' &&
            item !== 'isSending'
          ) {
            if (item === 'dates') {
              formObj[item] = subscriptionForm[item].filter(item =>
                item ? true : false
              )
            } else {
              formObj[item] = subscriptionForm[item]
            }
          }
        })

        formObj.categories = formObj.categories.map(item => ({
          category_id: parseInt(item.category_id),
          price: item.price,
        }))

        formObj.places = formObj.places.map(item => ({
          place_id: parseInt(item.place_id),
          num_visits: item.num_visits,
        }))

        if (!subscriptionForm.id) {
          this.props.createSubscription(formObj)
        } else {
          this.props.updateSubscription(subscriptionForm.id, formObj)
        }
      }
    })
  }

  render() {
    const antIcon = <Icon type="loading" style={{ fontSize: 40 }} spin />

    const { getFieldDecorator } = this.props.form
    const { user } = this.props

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    }

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    }

    const subscriptionForm = this.props.subscriptions.subscriptionForm
    const isDisabled = subscriptionForm.booked_subscriptions > 0
    const disableAll = !hasUserAccess(user.accessSettings, 'update_subscriptions')

    const categoriesDataSource = subscriptionForm.categories.map(item => ({
      key: item.category_id,
      name: this.getCategoryNameById(item.category_id),
      price: (
        <InputNumber
          placeholder="Введите"
          value={item.price}
          disabled={disableAll}
          min={0}
          onChange={value =>
            this.props.setPriceForCategory(item.category_id, value)
          }
        />
      ),
    }))

    const categoriesColumns = [
      {
        title: 'Название',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Цена',
        dataIndex: 'price',
        key: 'price',
      },
    ]

    const placesDataSource = subscriptionForm.places.map(item => ({
      key: item.place_id,
      name: this.getPlaceNameById(item.place_id),
      num_visits: (
        <InputNumber
          placeholder="Введите"
          disabled={disableAll}
          value={item.num_visits}
          min={1}
          onChange={value =>
            this.props.setNumVisitsForPlace(item.place_id, value)
          }
        />
      ),
    }))

    const placesColumns = [
      {
        title: 'Название',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Посещений',
        dataIndex: 'num_visits',
        key: 'num_visits',
      },
    ]

    const ticketsUrl = process.env.REACT_APP_TICKETS_DOMAIN

    return subscriptionForm.isOpen ? (
      <Spin spinning={subscriptionForm.isSending} indicator={antIcon}>
        {subscriptionForm.id && subscriptionForm.booked_subscriptions > 0 && (
          <p>
            Для абонементов, которые уже были заказаны, некоторые поля недоступны
            для редактирования.
            <br />
            Для текущего абонемента <strong>заказано: </strong>{' '}
            {subscriptionForm.booked_subscriptions}, <strong>оплачено: </strong>{' '}
            {subscriptionForm.subscriptions_sold}
          </p>
        )}
        <Form {...formItemLayout} onSubmit={this.handleSubmit}>
          <Form.Item label="Название">
            {getFieldDecorator('title', {
              rules: [{ required: true, message: 'Введите' }],
              initialValue: subscriptionForm.title,
            })(
              <Input
                placeholder="Введите"
                disabled={isDisabled || disableAll}
                onChange={e =>
                  this.props.setFieldForSubscription('title', e.target.value)
                }
              />
            )}
          </Form.Item>

          <Form.Item label="Название EN">
            {getFieldDecorator('title_en', {
              rules: [{ required: true, message: 'Введите' }],
              initialValue: subscriptionForm.title_en,
            })(
              <Input
                placeholder="Введите"
                disabled={isDisabled || disableAll}
                onChange={e =>
                  this.props.setFieldForSubscription('title_en', e.target.value)
                }
              />
            )}
          </Form.Item>

          <Form.Item label="Название для чека" help="Сокращенное название абонемента до 70 символов">
            {getFieldDecorator('receipt_title', {
              rules: [{ required: true, message: 'Введите' }],
              initialValue: subscriptionForm.receipt_title,
            })(
              <Input
                placeholder="Введите"
                disabled={disableAll}
                maxLength={70}
                onChange={e =>
                  this.props.setFieldForSubscription('receipt_title', e.target.value)
                }
              />
            )}
          </Form.Item>

          <Form.Item label="Подзаголовок">
            {getFieldDecorator('subtitle', {
              rules: [{ required: false, message: 'Введите' }],
              initialValue: subscriptionForm.subtitle,
            })(
              <Input
                placeholder="Введите"
                disabled={isDisabled || disableAll}
                onChange={e =>
                  this.props.setFieldForSubscription('subtitle', e.target.value)
                }
              />
            )}
          </Form.Item>

          <Form.Item label="Подзаголовок EN">
            {getFieldDecorator('subtitle_en', {
              rules: [{ required: false, message: 'Введите' }],
              initialValue: subscriptionForm.subtitle_en,
            })(
              <Input
                placeholder="Введите"
                disabled={isDisabled || disableAll}
                onChange={e =>
                  this.props.setFieldForSubscription('subtitle_en', e.target.value)
                }
              />
            )}
          </Form.Item>

          <Form.Item label="Возрастное ограничение">
            {getFieldDecorator('age_limit', {
              rules: [{ required: false, message: 'Введите' }],
              initialValue: subscriptionForm.age_limit,
            })(
              <Input
                placeholder="Введите"
                maxLength={50}
                disabled={disableAll}
                onChange={e =>
                  this.props.setFieldForSubscription('age_limit', e.target.value)
                }
              />
            )}
          </Form.Item>

          <Form.Item label="Комментарий">
            {getFieldDecorator('comment', {
              rules: [{ required: false, message: 'Введите' }],
              initialValue: subscriptionForm.comment,
            })(
              <Input
                placeholder="Введите"
                disabled={isDisabled || disableAll}
                onChange={e =>
                  this.props.setFieldForSubscription('comment', e.target.value)
                }
              />
            )}
          </Form.Item>

          <Form.Item label="Комментарий EN">
            {getFieldDecorator('comment_en', {
              rules: [{ required: false, message: 'Введите' }],
              initialValue: subscriptionForm.comment_en,
            })(
              <Input
                placeholder="Введите"
                disabled={isDisabled || disableAll}
                onChange={e =>
                  this.props.setFieldForSubscription('comment_en', e.target.value)
                }
              />
            )}
          </Form.Item>

          <Form.Item label="Абонементов в продаже">
            {getFieldDecorator('sales_limit', {
              rules: [{ required: true, message: 'Выберите' }],
              initialValue: subscriptionForm.sales_limit,
            })(
              <InputNumber
                placeholder="Введите"
                min={0}
                disabled={disableAll}
                onChange={value =>
                  this.props.setFieldForSubscription('sales_limit', value)
                }
              />
            )}
          </Form.Item>

          <Form.Item label="Лимит в заказе">
            {getFieldDecorator('order_limit', {
              rules: [{ required: true, message: 'Выберите' }],
              initialValue: subscriptionForm.order_limit,
            })(
              <InputNumber
                placeholder="Введите"
                min={1}
                disabled={disableAll}
                onChange={value =>
                  this.props.setFieldForSubscription('order_limit', value)
                }
              />
            )}
          </Form.Item>

          <Form.Item label="Доступно к продаже">
            <Switch
              checked={subscriptionForm.is_available_for_sale === 1 ? true : false}
              disabled={disableAll}
              onChange={value =>
                this.props.setFieldForSubscription(
                  'is_available_for_sale',
                  value ? 1 : 0
                )
              }
            />
          </Form.Item>

          <Form.Item label="Цвет">
            {getFieldDecorator('color', {
              rules: [{ required: false, message: 'Введите' }],
              initialValue: subscriptionForm.color,
            })(
              <Input
                placeholder="RGB"
                maxLength={6}
                disabled={disableAll}
                onChange={e =>
                  this.props.setFieldForSubscription('color', e.target.value)
                }
              />
            )}
          </Form.Item>

          <Form.Item label="Задать дату">
            <Switch
              disabled={isDisabled || disableAll}
              checked={subscriptionForm.is_date === 1 ? true : false}
              onChange={value => {
                this.props.setFieldForSubscription('is_date', value ? 1 : 0)
                if (!value) {
                  this.props.setFieldForSubscription('date_valid_from', null)
                  this.props.setFieldForSubscription('date_valid_to', null)
                }
              }}
            />
          </Form.Item>

          {subscriptionForm.is_date === 1 && (
            <>
              <Form.Item label="Начало">
                {getFieldDecorator('date_valid_from', {
                  rules: [{ required: true, message: 'Выберите время' }],
                  initialValue: subscriptionForm.date_valid_from
                    ? moment(subscriptionForm.date_valid_from, 'YYYY-MM-DD HH:mm')
                    : null,
                })(
                  <DatePicker
                    showTime={{
                      format: 'HH:mm',
                    }}
                    format={'DD.MM.YYYY HH:mm'}
                    disabled={isDisabled || disableAll}
                    placeholder="Выберите время"
                    onChange={(date, dateString) =>
                      this.props.setFieldForSubscription(
                        'date_valid_from',
                        moment(dateString, 'DD.MM.YYYY HH:mm').format(
                          'YYYY-MM-DD HH:mm'
                        )
                      )
                    }
                  />
                )}
              </Form.Item>

              <Form.Item label="Окончание">
                {getFieldDecorator('date_valid_to', {
                  rules: [{ required: true, message: 'Выберите время' }],
                  initialValue: subscriptionForm.date_valid_to
                    ? moment(subscriptionForm.date_valid_to, 'YYYY-MM-DD HH:mm')
                    : null,
                })(
                  <DatePicker
                    showTime={{
                      format: 'HH:mm',
                    }}
                    disabled={isDisabled || disableAll}
                    format={'DD.MM.YYYY HH:mm'}
                    placeholder="Выберите время"
                    onChange={(date, dateString) =>
                      this.props.setFieldForSubscription(
                        'date_valid_to',
                        moment(dateString, 'DD.MM.YYYY HH:mm').format(
                          'YYYY-MM-DD HH:mm'
                        )
                      )
                    }
                  />
                )}
              </Form.Item>
            </>
          )}

          <Form.Item label="Пушкинская карта">
            <Switch
              checked={subscriptionForm.is_pushka === 1 ? true : false}
              onChange={value => {
                this.props.setFieldForSubscription('is_pushka', value ? 1 : 0)
              }}
            />
          </Form.Item>

          <Form.Item label="ID события на Pro.Культуре">
            {getFieldDecorator('pro_culture_event_id', {
              rules: [{ required: subscriptionForm.is_pushka === 1, message: 'Введите' }],
              initialValue: subscriptionForm.pro_culture_event_id !== '0' ? subscriptionForm.pro_culture_event_id : null,
            })(
              <Input
                placeholder="Введите"
                disabled={disableAll}
                onChange={e =>
                  this.props.setFieldForSubscription(
                    'pro_culture_event_id',
                    e.target.value
                  )
                }
              />
            )}
          </Form.Item>

          <Form.Item label="ID места на Pro.Культуре">
            {getFieldDecorator('pro_culture_place_id', {
              rules: [{ required: subscriptionForm.is_pushka === 1, message: 'Введите' }],
              initialValue: subscriptionForm.pro_culture_place_id && subscriptionForm.pro_culture_place_id !== '0' ?
                subscriptionForm.pro_culture_place_id : null,
            })(
              <Input
                placeholder="Введите"
                disabled={disableAll}
                onChange={e =>
                  this.props.setFieldForSubscription(
                    'pro_culture_place_id',
                    e.target.value
                  )
                }
              />
            )}
          </Form.Item>

          <Form.Item label="Доступ по ссылке">
            <Switch
              checked={subscriptionForm.is_link_only_access === 1 ? true : false}
              disabled={disableAll}
              onChange={value => {
                this.props.setFieldForSubscription('is_link_only_access', value ? 1 : 0)
                this.props.setFieldForSubscription('link_access_code', value ? makeAccessCode(6) : '')
              }}
            />
          </Form.Item>

          {subscriptionForm.is_link_only_access === 1 && (
            <Form.Item
              label="Код доступа"
              extra={
                subscriptionForm.id ? `${ticketsUrl}/subscription/${subscriptionForm.id}?code=${subscriptionForm.link_access_code ? subscriptionForm.link_access_code : ''}` : ''
              }
            >
              {getFieldDecorator('link_access_code', {
                rules: [{ required: true, message: 'Введите' }],
                initialValue: subscriptionForm.link_access_code,
              })(
                <Input
                  placeholder="Введите"
                  disabled={disableAll}
                  maxLength={6}
                  onChange={e =>
                    this.props.setFieldForSubscription('link_access_code', e.target.value)
                  }
                />
              )}
            </Form.Item>
          )}

          <Form.Item label={'Места'}>
            {getFieldDecorator('place_ids', {
              rules: [{ required: true, message: 'Выберите' }],
              initialValue: subscriptionForm.place_ids,
            })(
              <Select
                mode="multiple"
                disabled={disableAll}
                placeholder="Выберите"
                filterOption={false}
                onChange={value => {
                  this.props.setFieldForSubscription('place_ids', value)
                  this.props.updatePlaces(value)
                }}
              >
                {this.props.common.places.map(item => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>

          <Form.Item label={'Посещения'}>
            <Table
              dataSource={placesDataSource}
              columns={placesColumns}
              pagination={false}
            />
          </Form.Item>

          <Form.Item label={'Категории'}>
            {getFieldDecorator('category_ids', {
              rules: [{ required: true, message: 'Выберите' }],
              initialValue: subscriptionForm.category_ids,
            })(
              <Select
                mode="multiple"
                disabled={disableAll}
                placeholder="Выберите"
                filterOption={false}
                onChange={value => {
                  this.props.setFieldForSubscription('category_ids', value)
                  this.props.updateCategories(value)
                }}
              >
                {this.props.common.categories.map(item => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>

          <Form.Item label={'Цены'}>
            <Table
              dataSource={categoriesDataSource}
              columns={categoriesColumns}
              pagination={false}
            />
          </Form.Item>

          {!disableAll && (
            <Form.Item {...tailFormItemLayout}>
              <Button type="primary" htmlType="submit">
                Сохранить
              </Button>
            </Form.Item>
          )}
        </Form>
      </Spin>
    ) : null
  }
}

const mapStateToProps = store => {
  return {
    subscriptions: store.subscriptions,
    common: store.common,
    user: store.user,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getPlacesList: () => dispatch(getPlacesList()),
    getCategoriesList: () => dispatch(getCategoriesList()),
    setFieldForSubscription: (field, value) =>
      dispatch(setFieldForSubscription(field, value)),
    updateCategories: value => dispatch(updateCategories(value)),
    setPriceForCategory: (id, value) =>
      dispatch(setPriceForCategory(id, value)),
    updatePlaces: value => dispatch(updatePlaces(value)),
    setNumVisitsForPlace: (id, value) =>
      dispatch(setNumVisitsForPlace(id, value)),
    createSubscription: formObj => dispatch(createSubscription(formObj)),
    updateSubscription: (id, formObj) => dispatch(updateSubscription(id, formObj)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: 'SubscriptionForm' })(SubscriptionForm))