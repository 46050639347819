import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Icon,
  Spin,
  Row,
  Col,
  DatePicker,
  Table,
  Popconfirm,
} from 'antd'
import moment from 'moment'

import {
  approveOrder,
  updateOrder,
  cancelOrder,
  setFieldForOrder,
} from '../../../../redux/actions/OrdersActions'

import hasUserAccess from '../../../../utils/hasUserAccess'

import { getStatus } from '../../../../utils/orderFunc'

class OrderForm extends PureComponent {
  getOrderSums(order) {
    let sum = 0
    let count = 0
    let usedCount = 0
    let refundSum = 0

    if (order.tickets) {
      count = order.tickets.length

      order.tickets.forEach(item => {
        sum += item.price
        if (item.was_used) {
          usedCount++
        } else {
          refundSum += item.price
        }
      })
    }

    return {
      sum,
      count,
      usedCount,
      refundSum,
    }
  }

  render() {
    const antIcon = <Icon type="loading" style={{ fontSize: 40 }} spin />

    const ticketsUrl = process.env.REACT_APP_TICKETS_DOMAIN

    const { user } = this.props
    const { orderForm } = this.props.orders

    const dataLayout = {
      title: 7,
      value: 17,
    }

    const dataSource = orderForm.data.tickets
      ? orderForm.data.tickets.map(item => ({
        key: item.ticket_id,
        category: item.category_name,
        price: item.price,
        used: item.was_used ? 'Да, ' + item.used_date : 'Нет',
      }))
      : []

    const { sum, count } = this.getOrderSums(orderForm.data)

    const columns = [
      {
        title: 'Категория',
        dataIndex: 'category',
        key: 'category',
      },
      {
        title: 'Цена',
        dataIndex: 'price',
        key: 'price',
      },
      {
        title: 'Использован',
        dataIndex: 'used',
        key: 'used',
      },
    ]

    return (
      <>
        {orderForm.isLoadingOrder && (
          <div className="Activity__loader">
            <Spin indicator={antIcon} />
          </div>
        )}

        {orderForm.data.id && (
          <Spin indicator={antIcon} spinning={orderForm.isUpdatingOrder}>
            <Row gutter={16} className="ActivityForm__row">
              <Col span={dataLayout.title}>
                Дата создания:
              </Col>
              <Col span={dataLayout.value}>
                {moment(orderForm.data.created_date, 'YYYY-MM-DD HH:mm').format(
                  'DD.MM.YYYY HH:mm'
                )}
              </Col>
            </Row>

            <Row gutter={16} className="ActivityForm__row">
              <Col span={dataLayout.title}>
                ФИО:
              </Col>
              <Col span={dataLayout.value}>
                {orderForm.data.user_fio}
              </Col>
            </Row>

            <Row gutter={16} className="ActivityForm__row">
              <Col span={dataLayout.title}>
                Телефон:
              </Col>
              <Col span={dataLayout.value}>
                {orderForm.data.user_phone}
              </Col>
            </Row>

            <Row gutter={16} className="ActivityForm__row">
              <Col span={dataLayout.title}>
                E-mail:
              </Col>
              <Col span={dataLayout.value}>
                {orderForm.data.user_email}
              </Col>
            </Row>

            <Row gutter={16} className="ActivityForm__row">
              <Col span={dataLayout.title}>
                Статус:
              </Col>
              <Col span={dataLayout.value}>
                {getStatus(orderForm.data.status)}
              </Col>
            </Row>

            <Row gutter={16} className="ActivityForm__row">
              <Col span={dataLayout.title}>
                Тип:
              </Col>
              <Col span={dataLayout.value}>
                {orderForm.data.is_online ? 'онлайн' : 'в кассе'}
              </Col>
            </Row>

            {orderForm.data.is_online === 1 && (
              <Row gutter={16} className="ActivityForm__row">
                <Col span={dataLayout.title}>
                  Ссылка:
                </Col>
                <Col span={dataLayout.value}>
                  <a
                    href={ticketsUrl + '/order/' + orderForm.data.hash}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    перейти
                  </a>
                </Col>
              </Row>
            )}

            <Row gutter={16} className="ActivityForm__row">
              <Col span={dataLayout.title}>
                Подтвержден:
              </Col>
              <Col span={dataLayout.value}>
                {orderForm.data.is_approved ? 'да' : 'нет'}
              </Col>
            </Row>

            {orderForm.data.visitation_date && (
              <Row gutter={16} className="ActivityForm__row">
                <Col span={dataLayout.title}>
                  Дата посещения:
                </Col>
                <Col span={dataLayout.value}>
                  {orderForm.data.status === 'ACTIVE' ? (
                    <>
                      <DatePicker
                        value={moment(orderForm.data.visitation_date, 'YYYY-MM-DD HH:mm')}
                        format={'DD.MM.YYYY HH:mm'}
                        showTime={{ format: 'HH:mm' }}
                        style={{ width: '80%', marginBottom: '1rem' }}
                        onChange={(value, dateString) =>
                          this.props.setFieldForOrder(
                            'visitation_date',
                            value.format('YYYY-MM-DD HH:mm:ss')
                          )
                        }
                        disabled={
                          !hasUserAccess(user.accessSettings, 'update_orders') &&
                          !hasUserAccess(user.accessSettings, 'approve_orders')
                        }
                      />
                      {orderForm.data.is_approved === 0 ? (
                        <Button
                          type="primary"
                          disabled={!hasUserAccess(user.accessSettings, 'approve_orders')}
                          onClick={() =>
                            this.props.approveOrder(
                              orderForm.data.id,
                              orderForm.data.visitation_date
                            )}
                        >
                          Подтвердить
                        </Button>
                      ) : (
                        <Button
                          type="primary"
                          disabled={!hasUserAccess(user.accessSettings, 'update_orders')}
                          onClick={() =>
                            this.props.updateOrder(
                              orderForm.data.id,
                              {
                                visitation_date: orderForm.data.visitation_date,
                              }
                            )}
                        >
                          Изменить
                        </Button>
                      )}

                      <Popconfirm
                        title="Вы уверены, что хотите отменить заказ?"
                        onConfirm={() => this.props.cancelOrder(orderForm.data.id)}
                        okText="Да"
                        cancelText="Нет"
                        disabled={!hasUserAccess(user.accessSettings, 'approve_orders')}
                      >
                        <Button
                          style={{ marginLeft: '1rem' }}
                          disabled={!hasUserAccess(user.accessSettings, 'approve_orders')}
                        >
                          Отменить
                        </Button>
                      </Popconfirm>
                    </>
                  ) : (
                    <>
                      {moment(orderForm.data.visitation_date, 'YYYY-MM-DD HH:mm').format(
                        'DD.MM.YYYY HH:mm'
                      )}
                    </>
                  )}
                </Col>
              </Row>
            )}

            {
              orderForm.data.sold_certificate.sold_certificate_id && (
                <Row gutter={16} className="ActivityForm__row">
                  <Col span={dataLayout.title}>
                    Оплачено сертификатом:
                  </Col>
                  <Col span={dataLayout.value}>
                    {orderForm.data.discount}₽
                  </Col>
                </Row>
              )
            }

            <Row gutter={16} className="ActivityForm__row">
              <Col span={dataLayout.title}>
                Билеты:
              </Col>
              <Col span={dataLayout.value}>
                <Table
                  dataSource={dataSource}
                  columns={columns}
                  pagination={false}
                  style={{ width: '500px', marginTop: '1rem' }}
                />

                <p style={{ marginTop: '1em', marginLeft: '1rem' }}>
                  <b>
                    Билетов: {count}, Сумма: {sum}₽
                  </b>
                </p>
              </Col>
            </Row>

            <Row gutter={16} className="ActivityForm__row">
              <Col span={dataLayout.title}></Col>
              <Col span={dataLayout.value}>
                {orderForm.data.cancelled_user.cancelled_user_id && (
                  <p>
                    Отменен:{' '}
                    {orderForm.data.cancelled_user.cancelled_user_surname}{' '}
                    {orderForm.data.cancelled_user.cancelled_user_name},{' '}
                    {moment(orderForm.data.cancelled_date, 'YYYY-MM-DD HH:mm').format(
                      'DD.MM.YYYY HH:mm'
                    )}
                  </p>
                )}
                {orderForm.data.approved_user.approved_user_id && (
                  <p>
                    Подтвержден:{' '}
                    {orderForm.data.approved_user.approved_user_surname}{' '}
                    {orderForm.data.approved_user.approved_user_name},{' '}
                    {moment(orderForm.data.approved_date, 'YYYY-MM-DD HH:mm').format(
                      'DD.MM.YYYY HH:mm'
                    )}
                  </p>
                )}
              </Col>
            </Row>

          </Spin>
        )}
      </>
    )
  }
}

const mapStateToProps = store => {
  return {
    orders: store.orders,
    user: store.user,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    approveOrder: (id, visitationDate) => dispatch(approveOrder(id, visitationDate)),
    updateOrder: (id, formObj) => dispatch(updateOrder(id, formObj)),
    cancelOrder: (id) => dispatch(cancelOrder(id)),
    setFieldForOrder: (field, value) => dispatch(setFieldForOrder(field, value)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderForm)