import produce from 'immer'
import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_NOT_FOUND,
  USER_LOGIN_SERVER_ERROR,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USERS_LIST_REQUEST,
  USERS_LIST_SUCCESS,
  USER_ACCESS_SETTINGS_REQUEST,
  USER_ACCESS_SETTINGS_SUCCESS,
} from '../actions/UserActions'

const initialState = {
  __persist: true,
  isLoading: false,
  authorized: false,
  type: 'person',
  id: '',
  attributes: {
    login: '',
    name: '',
    token: '',
  },
  error: {
    code: null,
    text: '',
  },

  users_list: [],
  isLoadingUsersList: false,

  accessSettings: null,
  isLoadingAccessSettings: false,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case USER_ACCESS_SETTINGS_SUCCESS:
      return produce(state, draftState => {
        draftState.isLoadingAccessSettings = false
        draftState.accessSettings = action.payload.data
      })
    case USER_ACCESS_SETTINGS_REQUEST:
      return produce(state, draftState => {
        draftState.isLoadingAccessSettings = true
      })
    case USERS_LIST_SUCCESS:
      return produce(state, draftState => {
        draftState.isLoadingUsersList = false
        draftState.users_list = action.payload.data
      })
    case USERS_LIST_REQUEST:
      return produce(state, draftState => {
        draftState.isLoadingUsersList = true
      })
    case USER_LOGIN_REQUEST:
      return produce(state, draftState => {
        draftState.isLoading = true
      })
    case USER_LOGIN_NOT_FOUND:
      return produce(state, draftState => {
        draftState.isLoading = false
        draftState.error = {
          code: 404,
          text: 'Введен неверный логин или пароль. После пяти неудачных попыток входа доступ в модуль будет заблокирован',
        }
      })
    case USER_LOGIN_SERVER_ERROR:
      return produce(state, draftState => {
        draftState.isLoading = false
        draftState.authorized = false
        draftState.error = {
          code: 500,
          text:
            'Возникла проблема со входом. Попробуйте еще раз и обратитесь в службу поддержки, если проблема повторится',
        }
      })
    case USER_LOGIN_SUCCESS:
      const { data } = action.payload
      return produce(state, draftState => {
        draftState.isLoading = false
        draftState.authorized = true
        draftState.error = {
          code: null,
          text: '',
        }
        draftState.id = data.id
        draftState.attributes.login = data.login
        draftState.attributes.token = data.token
        draftState.attributes.name = data.name + ' ' + data.surname
      })
    case USER_LOGOUT:
      return initialState
    default:
      return state
  }
}
