export function getStatus(status) {
  let statusStr = ''

  switch (status) {
    case 'ACTIVE':
      statusStr = 'ожидает оплаты'
      break
    case 'PAID':
      statusStr = 'оплачен'
      break
    case 'CANCELED':
      statusStr = 'отменен'
      break
    case 'RETURNED':
      statusStr = 'возвращен'
      break
    default:
      break
  }

  return statusStr
}

export function getPaymentMethodName(key) {
  let paymentMethodForModal = ''

  switch (key) {
    case 1:
      paymentMethodForModal = 'наличные'
      break
    case 2:
      paymentMethodForModal = 'договор'
      break
    default:
      paymentMethodForModal = 'банковская карта'
      break
  }

  return paymentMethodForModal
}
