import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import {
  Form,
  Select,
  InputNumber,
  Input,
  Button,
  Switch,
  Icon,
  Spin,
  Table,
  Alert
} from 'antd'

import {
  setFieldForTicket,
  updateCategories,
  setPriceForCategory,
  createTicket,
  updateTicket,
} from '../../../../redux/actions/TicketsActions'
import {
  getBranchesList,
  getPlacesList,
  getEventsList,
  getCategoriesList,
  getTypesList,
  getExcursionsList,
} from '../../../../redux/actions/CommonActions'
import makeAccessCode from '../../../../utils/makeAccessCode'
import hasUserAccess from '../../../../utils/hasUserAccess'

import TemporarySeancesTable from './TemporarySeancesTableComponent'
import RegularSeancesTable from './RegularSeancesTableComponent'
import UploadImage from './UploadImageComponent'

const { Option } = Select

class TicketForm extends PureComponent {
  state = {
    eventsList: [],
    excursionsList: [],
  }

  componentDidMount = () => {
    this.props.getBranchesList()
    this.props.getPlacesList()
    this.props.getCategoriesList()
    this.props.getTypesList()

    this.props.getEventsList()
      .then(action => {
        this.setState({ eventsList: action.payload.data })
      })
      .catch(error => {
        console.error(error)
      })

    this.props.getExcursionsList()
      .then(action => {
        this.setState({ excursionsList: action.payload.data })
      })
      .catch(error => {
        console.error(error)
      })
  }

  searchEvents = value => {
    value = value.toLowerCase()
    const searchList = this.props.common.events
    let eventsList = []
    searchList.forEach(item => {
      if (item.name.toLowerCase().includes(value)) {
        eventsList.push(item)
      }
    })
    this.setState({ eventsList })
  }

  searchExcursions = value => {
    value = value.toLowerCase()
    const searchList = this.props.common.excursions
    let excursionsList = []
    searchList.forEach(item => {
      if (item.name.toLowerCase().includes(value)) {
        excursionsList.push(item)
      }
    })
    this.setState({ excursionsList })
  }

  getCategoryNameById = id => {
    let name = ''
    this.props.common.categories.forEach(item => {
      if (item.id === id) {
        name = item.name
      }
    })
    return name
  }

  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { ticketForm } = this.props.tickets
        let formObj = {}

        Object.keys(ticketForm).forEach(item => {
          if (
            ticketForm[item] !== null &&
            item !== 'id' &&
            item !== 'isOpen' &&
            item !== 'isSending'
          ) {
            if (item === 'dates') {
              formObj[item] = ticketForm[item].filter(item =>
                item ? true : false
              )
            } else {
              formObj[item] = ticketForm[item]
            }
          }
        })

        formObj.categories = formObj.categories.map(item => ({
          category_id: parseInt(item.category_id),
          price: item.price,
        }))

        if (!ticketForm.id) {
          this.props.createTicket(formObj)
        } else {
          this.props.updateTicket(ticketForm.id, formObj)
        }
      }
    })
  }

  render() {
    const antIcon = <Icon type="loading" style={{ fontSize: 40 }} spin />

    const { getFieldDecorator } = this.props.form
    const { user } = this.props

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    }

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    }

    const ticketForm = this.props.tickets.ticketForm
    const placesList = this.props.common.places
    const isDisabled = ticketForm.booked_tickets > 0
    const disableAll = !hasUserAccess(user.accessSettings, 'update_tickets')

    const categoriesDataSource = ticketForm.categories.map(item => ({
      key: item.category_id,
      name: this.getCategoryNameById(item.category_id),
      price: (
        <InputNumber
          placeholder="Введите"
          value={item.price}
          min={0}
          disabled={disableAll}
          onChange={value =>
            this.props.setPriceForCategory(item.category_id, value)
          }
        />
      ),
    }))

    const columns = [
      {
        title: 'Название',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Цена',
        dataIndex: 'price',
        key: 'price',
      },
    ]

    const ticketsUrl = process.env.REACT_APP_TICKETS_DOMAIN

    const selectedPlaceIndex = placesList.findIndex(
      item => parseInt(item.id) === parseInt(ticketForm.place_id)
    )
    const selectedPlaceObj = selectedPlaceIndex >= 0 ? placesList[selectedPlaceIndex] : null

    const ACTIVATE_SEND_TO_DIS = process.env.REACT_APP_ACTIVATE_SEND_TO_DIS

    return ticketForm.isOpen ? (
      <Spin spinning={ticketForm.isSending} indicator={antIcon}>
        {ticketForm.id && ticketForm.booked_tickets > 0 && (
          <p>
            Для билетов, которые уже были заказаны, некоторые поля недоступны
            для редактирования.
            <br />
            Для текущего билета <strong>заказано: </strong>{' '}
            {ticketForm.booked_tickets}, <strong>оплачено: </strong>{' '}
            {ticketForm.tickets_sold}
          </p>
        )}
        <Form {...formItemLayout} onSubmit={this.handleSubmit}>
          <Form.Item label="Название">
            {getFieldDecorator('title', {
              rules: [{ required: true, message: 'Введите' }],
              initialValue: ticketForm.title,
            })(
              <Input
                placeholder="Введите"
                disabled={isDisabled || disableAll}
                onChange={e =>
                  this.props.setFieldForTicket('title', e.target.value)
                }
              />
            )}
          </Form.Item>

          <Form.Item label="Название EN">
            {getFieldDecorator('title_en', {
              rules: [{ required: true, message: 'Введите' }],
              initialValue: ticketForm.title_en,
            })(
              <Input
                placeholder="Введите"
                disabled={isDisabled || disableAll}
                onChange={e =>
                  this.props.setFieldForTicket('title_en', e.target.value)
                }
              />
            )}
          </Form.Item>

          <Form.Item label="Название для чека" help="Сокращенное название билета до 70 символов">
            {getFieldDecorator('receipt_title', {
              rules: [{ required: true, message: 'Введите' }],
              initialValue: ticketForm.receipt_title,
            })(
              <Input
                placeholder="Введите"
                maxLength={70}
                disabled={disableAll}
                onChange={e =>
                  this.props.setFieldForTicket('receipt_title', e.target.value)
                }
              />
            )}
          </Form.Item>

          <Form.Item label="Возрастное ограничение">
            {getFieldDecorator('age_limit', {
              rules: [{ required: false, message: 'Введите' }],
              initialValue: ticketForm.age_limit,
            })(
              <Select
                placeholder="Выберите"
                filterOption={false}
                disabled={disableAll}
                onChange={value =>
                  this.props.setFieldForTicket('age_limit', value)
                }
              >
                <Option value={0}>
                  0+
                </Option>
                <Option value={6}>
                  6+
                </Option>
                <Option value={12}>
                  12+
                </Option>
                <Option value={16}>
                  16+
                </Option>
                <Option value={18}>
                  18+
                </Option>
              </Select>
            )}
          </Form.Item>

          <Form.Item label="Комментарий">
            {getFieldDecorator('comment', {
              rules: [{ required: false, message: 'Введите' }],
              initialValue: ticketForm.comment,
            })(
              <Input
                placeholder="Введите"
                disabled={isDisabled || disableAll}
                onChange={e =>
                  this.props.setFieldForTicket('comment', e.target.value)
                }
              />
            )}
          </Form.Item>

          <Form.Item label="Комментарий EN">
            {getFieldDecorator('comment_en', {
              rules: [{ required: false, message: 'Введите' }],
              initialValue: ticketForm.comment_en,
            })(
              <Input
                placeholder="Введите"
                disabled={isDisabled || disableAll}
                onChange={e =>
                  this.props.setFieldForTicket('comment_en', e.target.value)
                }
              />
            )}
          </Form.Item>

          <Form.Item label="Изображение">
            <UploadImage disabled={disableAll} />
          </Form.Item>

          <Form.Item label="Тип">
            {getFieldDecorator('type_id', {
              rules: [{ required: true, message: 'Выберите' }],
              initialValue: ticketForm.type_id
                ? ticketForm.type_id
                : undefined,
            })(
              <Select
                placeholder="Выберите"
                filterOption={false}
                disabled={disableAll}
                onChange={value =>
                  this.props.setFieldForTicket('type_id', value)
                }
              >
                {this.props.common.types.map(item => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>

          <Form.Item label="Филиал">
            {getFieldDecorator('branch_id', {
              rules: [{ required: true, message: 'Выберите' }],
              initialValue: ticketForm.branch_id
                ? ticketForm.branch_id + ''
                : undefined,
            })(
              <Select
                placeholder="Выберите"
                filterOption={false}
                disabled={isDisabled || disableAll}
                onChange={value =>
                  this.props.setFieldForTicket('branch_id', value)
                }
              >
                {this.props.common.branches.map(item => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>

          <Form.Item label="Место">
            {getFieldDecorator('place_id', {
              rules: [{ required: true, message: 'Выберите' }],
              initialValue: ticketForm.place_id
                ? ticketForm.place_id + ''
                : undefined,
            })(
              <Select
                placeholder="Выберите"
                filterOption={false}
                disabled={isDisabled || disableAll}
                onChange={value =>
                  this.props.setFieldForTicket('place_id', value)
                }
              >
                {placesList.map(item => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>

          <Form.Item label="Экскурсия">
            {getFieldDecorator('excursion_id', {
              rules: [{ required: false, message: 'Выберите' }],
              initialValue: ticketForm.excursion_id
                ? ticketForm.excursion_id + ''
                : undefined,
            })(
              <Select
                placeholder="Выберите"
                showSearch
                onSearch={this.searchExcursions}
                filterOption={false}
                onChange={value =>
                  this.props.setFieldForTicket('excursion_id', value)
                }
                allowClear={true}
                disabled={disableAll}
              >
                {this.state.excursionsList.map(item => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>

          <Form.Item label={'Событие'}>
            {getFieldDecorator('event_id', {
              rules: [{ required: false, message: 'Выберите' }],
              initialValue: ticketForm.event_id
                ? ticketForm.event_id + ''
                : undefined,
            })(
              <Select
                disabled={isDisabled}
                placeholder="Выберите"
                showSearch
                onSearch={this.searchEvents}
                filterOption={false}
                onChange={value =>
                  this.props.setFieldForTicket('event_id', value)
                }
                allowClear={true}
                disabled={disableAll}
              >
                {this.state.eventsList.map(item => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>

          <Form.Item label="Лимит в заказе">
            {getFieldDecorator('order_limit', {
              rules: [{ required: true, message: 'Выберите' }],
              initialValue: ticketForm.order_limit,
            })(
              <InputNumber
                placeholder="Введите"
                min={1}
                disabled={disableAll}
                onChange={value =>
                  this.props.setFieldForTicket('order_limit', value)
                }
              />
            )}
          </Form.Item>

          <Form.Item label="Посещение">
            {getFieldDecorator('visitation_type', {
              rules: [{ required: true, message: 'Введите' }],
              initialValue: ticketForm.visitation_type,
            })(
              <Select
                placeholder="Выберите"
                filterOption={false}
                disabled={!!ticketForm.id || disableAll}
                onChange={value => {
                  this.props.setFieldForTicket('visitation_type', value)
                  if (value === 0) {
                    this.props.setFieldForTicket('send_to_dis', 0)
                    this.props.setFieldForTicket('sale_in_dis', 0)
                  }

                  if (value !== 1) {
                    this.props.setFieldForTicket('seances_generation_days', 0)
                    this.props.setFieldForTicket('seances_settings', null)
                  } else {
                    this.props.setFieldForTicket('seances_settings', {
                      Mo: {
                        is_active: false,
                        type: '', // '' | fullday | interval | schedule
                        settings: null
                      },
                      Tu: {
                        is_active: false,
                        type: '',
                        settings: null
                      },
                      We: {
                        is_active: false,
                        type: '',
                        settings: null
                      },
                      Th: {
                        is_active: false,
                        type: '',
                        settings: null
                      },
                      Fr: {
                        is_active: false,
                        type: '',
                        settings: null
                      },
                      Sa: {
                        is_active: false,
                        type: '',
                        settings: null
                      },
                      Su: {
                        is_active: false,
                        type: '',
                        settings: null
                      },
                    })
                  }

                  if (value !== 2) {
                    this.props.setFieldForTicket('seances', [])
                  }
                }}
              >
                <Option value={0}>
                  Постоянное
                </Option>
                <Option value={1}>
                  Регулярные сеансы
                </Option>
                <Option value={2}>
                  Временные сеансы
                </Option>
              </Select>
            )}
          </Form.Item>

          {ticketForm.visitation_type === 0 && (
            <Form.Item label={'Подтверждение заказа'}>
              <Switch
                checked={ticketForm.is_has_to_be_approved === 1 ? true : false}
                disabled={disableAll}
                onChange={value => {
                  this.props.setFieldForTicket(
                    'is_has_to_be_approved',
                    value ? 1 : 0
                  )

                  if (!value) {
                    this.props.setFieldForTicket('is_pushka', 0)
                    this.props.setFieldForTicket('is_only_pushka', 0)
                  }
                }
                }
              />
            </Form.Item>
          )}

          {ticketForm.visitation_type === 1 && (
            <Form.Item label="Генерация сеансов">
              {getFieldDecorator('seances_generation_days', {
                rules: [{ required: true, message: 'Введите' }],
                initialValue: ticketForm.seances_generation_days,
              })(
                <Select
                  placeholder="Выберите"
                  filterOption={false}
                  disabled={disableAll}
                  onChange={value => {
                    this.props.setFieldForTicket('seances_generation_days', value)
                  }}
                >
                  <Option value={0}>
                    Выключена
                  </Option>
                  <Option value={1}>
                    На 1 день
                  </Option>
                  <Option value={3}>
                    На 3 дня
                  </Option>
                  <Option value={7}>
                    На 7 дней
                  </Option>
                  <Option value={14}>
                    На 14 дней
                  </Option>
                  <Option value={30}>
                    На 30 дней
                  </Option>
                </Select>
              )}
            </Form.Item>
          )}

          {ticketForm.visitation_type === 1 && (
            <Form.Item label={'Сеансы'}>
              <RegularSeancesTable disabled={disableAll} />
            </Form.Item>
          )}

          {ticketForm.visitation_type === 2 && (
            <Form.Item label={'Сеансы'}>
              <TemporarySeancesTable disabled={disableAll} />
            </Form.Item>
          )}

          <Form.Item label="Доступно к продаже">
            <Switch
              checked={ticketForm.is_available_for_sale === 1 ? true : false}
              disabled={disableAll}
              onChange={value =>
                this.props.setFieldForTicket(
                  'is_available_for_sale',
                  value ? 1 : 0
                )
              }
            />
          </Form.Item>

          <Form.Item label="Онлайн">
            <Switch
              disabled={ticketForm.is_available_for_sale !== 1 || disableAll}
              checked={ticketForm.is_online === 1 ? true : false}
              onChange={value => {
                this.props.setFieldForTicket('is_online', value ? 1 : 0)
              }}
            />
          </Form.Item>

          <Form.Item label="Оффлайн">
            <Switch
              disabled={ticketForm.is_available_for_sale !== 1 || disableAll}
              checked={ticketForm.is_offline === 1 ? true : false}
              onChange={value => {
                this.props.setFieldForTicket('is_offline', value ? 1 : 0)
              }}
            />
          </Form.Item>

          {ticketForm.is_has_to_be_approved === 0 && (
            <Form.Item label="Пушкинская карта">
              <Switch
                checked={ticketForm.is_pushka === 1 ? true : false}
                disabled={disableAll}
                onChange={value => {
                  this.props.setFieldForTicket('is_pushka', value ? 1 : 0)
                  if (!value) {
                    this.props.setFieldForTicket('is_only_pushka', 0)
                    this.props.setFieldForTicket('send_to_dis', 0)
                    this.props.setFieldForTicket('sale_in_dis', 0)
                  }
                }}
              />
            </Form.Item>
          )}

          {ticketForm.is_pushka === 1 && (
            <Form.Item label="Оплата только ПК">
              <Switch
                checked={ticketForm.is_only_pushka === 1 ? true : false}
                disabled={disableAll}
                onChange={value => {
                  this.props.setFieldForTicket('is_only_pushka', value ? 1 : 0)
                }}
              />
            </Form.Item>
          )}

          <Form.Item label="ID события на Pro.Культуре">
            {getFieldDecorator('pro_culture_event_id', {
              rules: [{ required: ticketForm.is_pushka === 1, message: 'Введите' }],
              initialValue: ticketForm.pro_culture_event_id !== '0' ? ticketForm.pro_culture_event_id : null,
            })(
              <Input
                placeholder="Введите"
                disabled={disableAll}
                onChange={e =>
                  this.props.setFieldForTicket(
                    'pro_culture_event_id',
                    e.target.value
                  )
                }
              />
            )}
          </Form.Item>

          <Form.Item label="ID места на Pro.Культуре">
            {getFieldDecorator('pro_culture_place_id', {
              rules: [{ required: ticketForm.is_pushka === 1, message: 'Введите' }],
              initialValue: ticketForm.pro_culture_place_id && ticketForm.pro_culture_place_id !== '0' ?
                ticketForm.pro_culture_place_id : null,
            })(
              <Input
                placeholder="Введите"
                disabled={disableAll}
                onChange={e =>
                  this.props.setFieldForTicket(
                    'pro_culture_place_id',
                    e.target.value
                  )
                }
              />
            )}
          </Form.Item>

          {ACTIVATE_SEND_TO_DIS && ticketForm.is_pushka === 1 && (
            <>
              {(ticketForm.visitation_type === 1 || ticketForm.visitation_type === 2) && (
                <>
                  <Form.Item label="Передавать в ДИС">
                    <Switch
                      checked={ticketForm.send_to_dis === 1 ? true : false}
                      disabled={disableAll}
                      onChange={value => {
                        this.props.setFieldForTicket('send_to_dis', value ? 1 : 0)
                        if (!value) {
                          this.props.setFieldForTicket('sale_in_dis', 0)
                        }
                      }}
                    />
                    {(
                      ticketForm.send_to_dis === 1 &&
                      selectedPlaceObj &&
                      selectedPlaceObj.address === ''
                    ) && (
                        <Alert
                          type="warning"
                          message="
                            У выбранного места не заполнено поле Адрес. 
                            Оно является обязательным для передачи в ДИС.
                            Укажите его и обновите страницу, чтобы сохранить изменения.
                          "
                        />
                      )}
                  </Form.Item>
                </>
              )}

              {(ticketForm.send_to_dis === 1) && (
                <>
                  <Form.Item label="Продавать в ДИС">
                    <Switch
                      disabled={ticketForm.is_available_for_sale !== 1 || disableAll}
                      checked={ticketForm.sale_in_dis === 1 ? true : false}
                      onChange={value => {
                        this.props.setFieldForTicket('sale_in_dis', value ? 1 : 0)
                      }}
                    />
                  </Form.Item>
                </>
              )}
            </>
          )}

          <Form.Item label="Доступ по ссылке">
            <Switch
              checked={ticketForm.is_link_only_access === 1 ? true : false}
              disabled={disableAll}
              onChange={value => {
                this.props.setFieldForTicket('is_link_only_access', value ? 1 : 0)
                this.props.setFieldForTicket('link_access_code', value ? makeAccessCode(6) : '')
              }}
            />
          </Form.Item>

          {ticketForm.is_link_only_access === 1 && (
            <Form.Item
              label="Код доступа"
              extra={
                ticketForm.id ? `${ticketsUrl}/ticket/${ticketForm.id}?code=${ticketForm.link_access_code ? ticketForm.link_access_code : ''}` : ''
              }
            >
              {getFieldDecorator('link_access_code', {
                rules: [{ required: true, message: 'Введите' }],
                initialValue: ticketForm.link_access_code,
              })(
                <Input
                  placeholder="Введите"
                  disabled={disableAll}
                  maxLength={6}
                  onChange={e =>
                    this.props.setFieldForTicket('link_access_code', e.target.value)
                  }
                />
              )}
            </Form.Item>
          )}

          <Form.Item label={'Категории'}>
            {getFieldDecorator('category_ids', {
              rules: [{ required: true, message: 'Выберите' }],
              initialValue: ticketForm.category_ids,
            })(
              <Select
                mode="multiple"
                placeholder="Выберите"
                filterOption={false}
                disabled={disableAll}
                onChange={value => {
                  this.props.setFieldForTicket('category_ids', value)
                  this.props.updateCategories(value)
                }}
              >
                {this.props.common.categories.map(item => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>

          <Form.Item label={'Цены'}>
            <Table
              dataSource={categoriesDataSource}
              columns={columns}
              pagination={false}
            />
          </Form.Item>

          {!disableAll && (
            <Form.Item {...tailFormItemLayout}>
              <Button
                type="primary"
                htmlType="submit"
                disabled={
                  (ticketForm.visitation_type === 2 && ticketForm.seances.length === 0) ||
                  (
                    ticketForm.send_to_dis === 1 &&
                    (!selectedPlaceObj || selectedPlaceObj.address === '')
                  )
                }
              >
                Сохранить
              </Button>
            </Form.Item>
          )}
        </Form>
      </Spin>
    ) : null
  }
}

const mapStateToProps = store => {
  return {
    tickets: store.tickets,
    common: store.common,
    user: store.user,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getEventsList: () => dispatch(getEventsList()),
    getBranchesList: () => dispatch(getBranchesList()),
    getPlacesList: () => dispatch(getPlacesList()),
    getCategoriesList: () => dispatch(getCategoriesList()),
    getTypesList: () => dispatch(getTypesList()),
    getExcursionsList: () => dispatch(getExcursionsList()),
    setFieldForTicket: (field, value) =>
      dispatch(setFieldForTicket(field, value)),
    updateCategories: value => dispatch(updateCategories(value)),
    setPriceForCategory: (id, value) =>
      dispatch(setPriceForCategory(id, value)),
    createTicket: formObj => dispatch(createTicket(formObj)),
    updateTicket: (id, formObj) => dispatch(updateTicket(id, formObj)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: 'TicketForm' })(TicketForm))
