import produce from 'immer'

import {
  ORDERS_LOAD_REQUEST,
  ORDERS_LOAD_SUCCESS,
  TOGGLE_ORDER_FORM,
  ORDER_LOAD_REQUEST,
  ORDER_LOAD_SUCCESS,
  APPROVE_ORDER_REQUEST,
  APPROVE_ORDER_SUCCESS,
  SET_FIELD_FOR_ORDER,
  UPDATE_ORDER_REQUEST,
  UPDATE_ORDER_SUCCESS,
  CANCEL_ORDER_REQUEST,
  CANCEL_ORDER_SUCCESS,
} from '../actions/OrdersActions'

const initialState = {
  isLoadingOrders: false,
  orders: [],
  animation: true,
  pageNumber: 1,
  isTotalReached: false,
  sort: {
    field: 'id',
    type: 'desc',
  },
  filter: {
    id: null,
    is_approved: 'all',
    status: null,
  },
  orderForm: {
    data: {
      id: null,
    },

    isOpen: false,
    isLoadingOrder: false,
    isUpdatingOrder: false,
  },
}

export default function (state = initialState, action) {
  switch (action.type) {
    case CANCEL_ORDER_REQUEST:
      return produce(state, draftState => {
        draftState.orderForm.isUpdatingOrder = true
      })
    case CANCEL_ORDER_SUCCESS:
      return produce(state, draftState => {
        draftState.orderForm.isUpdatingOrder = false
        draftState.orderForm.data = action.payload.data
      })
    case UPDATE_ORDER_REQUEST:
      return produce(state, draftState => {
        draftState.orderForm.isUpdatingOrder = true
      })
    case UPDATE_ORDER_SUCCESS:
      return produce(state, draftState => {
        draftState.orderForm.isUpdatingOrder = false
        draftState.orderForm.data = action.payload.data
      })
    case SET_FIELD_FOR_ORDER:
      return produce(state, draftState => {
        draftState.orderForm.data[action.payload.field] = action.payload.value
      })
    case APPROVE_ORDER_REQUEST:
      return produce(state, draftState => {
        draftState.orderForm.isUpdatingOrder = true
      })
    case APPROVE_ORDER_SUCCESS:
      return produce(state, draftState => {
        draftState.orderForm.isUpdatingOrder = false
        draftState.orderForm.data = action.payload.data
      })
    case ORDER_LOAD_REQUEST:
      return produce(state, draftState => {
        draftState.orderForm.isLoadingOrder = true
        draftState.orderForm.data = {
          id: null
        }
      })
    case ORDER_LOAD_SUCCESS:
      return produce(state, draftState => {
        draftState.orderForm.isLoadingOrder = false
        draftState.orderForm.data = action.payload.data
      })
    case TOGGLE_ORDER_FORM:
      return produce(state, draftState => {
        draftState.orderForm.isOpen = action.payload.isOpen
      })
    case ORDERS_LOAD_REQUEST:
      return produce(state, draftState => {
        draftState.isLoadingOrders = true
        draftState.isTotalReached = false

        const { params } = action.payload.request

        const pageNumber = params['page']
        if (pageNumber === 1) {
          draftState.orders = []
        }
        draftState.pageNumber = pageNumber

        draftState.filter.id = params['id']
          ? params['id']
          : null

        draftState.filter.is_approved = params['is_approved']
          ? params['is_approved']
          : 'all'

        draftState.filter.status = params['status']
          ? params['status']
          : null

        if (params['sort']) {
          draftState.sort.field = params['sort']
          draftState.sort.type = params['sort_type']
        } else {
          draftState.sort = initialState.sort
        }
      })
    case ORDERS_LOAD_SUCCESS:
      return produce(state, draftState => {
        draftState.isLoadingOrders = false
        draftState.animation = false

        if (draftState.pageNumber === 1) {
          draftState.orders = action.payload.data
        } else {
          draftState.isTotalReached = action.payload.data.length === 0
          draftState.orders = draftState.orders.concat(action.payload.data)
        }
      })
    default:
      return state
  }
}