export function getWeekDayNameByCode(code) {
  let weekdayName = ''
  switch (code) {
    case 'Mo':
      weekdayName = 'Понедельник'
      break
    case 'Tu':
      weekdayName = 'Вторник'
      break
    case 'We':
      weekdayName = 'Среда'
      break
    case 'Th':
      weekdayName = 'Четверг'
      break
    case 'Fr':
      weekdayName = 'Пятница'
      break
    case 'Sa':
      weekdayName = 'Суббота'
      break
    case 'Su':
      weekdayName = 'Воскресенье'
      break
    default:
      break
  }
  return weekdayName
}

export default function getWeekdayName(weekday) {
  let weekdayName = ''
  switch (weekday) {
    case 1:
      weekdayName = 'Понедельник'
      break
    case 2:
      weekdayName = 'Вторник'
      break
    case 3:
      weekdayName = 'Среда'
      break
    case 4:
      weekdayName = 'Четверг'
      break
    case 5:
      weekdayName = 'Пятница'
      break
    case 6:
      weekdayName = 'Суббота'
      break
    case 7:
      weekdayName = 'Воскресенье'
      break
    default:
      break
  }
  return weekdayName
}
