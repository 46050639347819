export const TICKETS_LOAD_REQUEST = 'TICKETS/TICKETS_LOAD_REQUEST'
export const TICKETS_LOAD_SUCCESS = 'TICKETS/TICKETS_LOAD_SUCCESS'

export function getTicketsList(pageNumber = 1, sort = null, filterForm = null) {
  return dispatch => {
    let additionalParams = {}

    if (sort) {
      additionalParams.sort = sort.field
      additionalParams.sort_type = sort.type
    }

    if (filterForm) {
      additionalParams.date_from = filterForm.dateFrom
      additionalParams.date_to = filterForm.dateTo
      additionalParams.place = filterForm.place.map(item =>
        parseInt(item)
      )
      additionalParams.available_for_sale = filterForm.available_for_sale
    }

    dispatch({
      types: [TICKETS_LOAD_REQUEST, TICKETS_LOAD_SUCCESS],
      payload: {
        request: {
          method: 'GET',
          url: '/ticket/get-tickets-list',
          params: {
            page: pageNumber,
            ...additionalParams,
          },
        },
      },
    })
  }
}

export const SET_AVAILABLE_FOR_SALE = 'TICKETS/SET_AVAILABLE_FOR_SALE'

export function setAvailableForSale(value) {
  return dispatch => {
    dispatch({
      type: SET_AVAILABLE_FOR_SALE,
      payload: {
        value,
      },
    })
  }
}

export const SET_DATE_RANGE = 'TICKETS/SET_DATE_RANGE'

export function setDateRange(dateString) {
  return dispatch => {
    dispatch({
      type: SET_DATE_RANGE,
      payload: {
        dateFrom: dateString[0],
        dateTo: dateString[1],
      },
    })
  }
}

export const SET_PLACE = 'TICKETS/SET_PLACE'

export function setPlace(place) {
  return dispatch => {
    dispatch({
      type: SET_PLACE,
      payload: {
        place
      },
    })
  }
}

export const RESET_FILTERS = 'TICKETS/RESET_FILTERS'

export function resetFilters() {
  return dispatch => {
    dispatch({
      type: RESET_FILTERS,
    })
  }
}

export const APPLY_FILTERS = 'TICKETS/APPLY_FILTERS'

export function applyFilters() {
  return dispatch => {
    dispatch({
      type: APPLY_FILTERS,
    })
  }
}

export const COPY_TICKET_REQUEST = 'TICKETS/COPY_TICKET_REQUEST'
export const COPY_TICKET_SUCCESS = 'TICKETS/COPY_TICKET_SUCCESS'

export function copyTicket(id) {
  return dispatch => {
    dispatch({
      types: [COPY_TICKET_REQUEST, COPY_TICKET_SUCCESS],
      payload: {
        request: {
          method: 'POST',
          url: `/ticket/copy/${id}`,
        },
        id,
      },
    })
  }
}

export const DELETE_TICKET_REQUEST = 'TICKETS/DELETE_TICKET_REQUEST'
export const DELETE_TICKET_SUCCESS = 'TICKETS/DELETE_TICKET_SUCCESS'

export function deleteTicket(id) {
  return dispatch => {
    dispatch({
      types: [DELETE_TICKET_REQUEST, DELETE_TICKET_SUCCESS],
      payload: {
        request: {
          method: 'POST',
          url: `/ticket/delete/${id}`,
        },
        id,
      },
    })
  }
}

export const OPEN_TICKET_FORM = 'TICKETS/OPEN_TICKET_FORM'

export function openTicketForm() {
  return dispatch => {
    dispatch({
      type: OPEN_TICKET_FORM,
    })
  }
}

export const CLOSE_TICKET_FORM = 'TICKETS/CLOSE_TICKET_FORM'

export function closeTicketForm() {
  return dispatch => {
    dispatch({
      type: CLOSE_TICKET_FORM,
    })
  }
}

export const OPEN_EDIT_TICKET_FORM = 'TICKETS/OPEN_EDIT_TICKET_FORM'

export function openEditTicketForm(id) {
  return dispatch => {
    dispatch({
      type: OPEN_EDIT_TICKET_FORM,
      payload: {
        id,
      },
    })
  }
}

// TICKET FORM START

export const SET_FIELD_FOR_TICKET = 'TICKETS/SET_FIELD_FOR_TICKET'

export function setFieldForTicket(field, value) {
  return dispatch => {
    dispatch({
      type: SET_FIELD_FOR_TICKET,
      payload: {
        field,
        value,
      },
    })
  }
}

export const UPDATE_CATEGORIES = 'TICKETS/UPDATE_CATEGORIES'

export function updateCategories(value) {
  return dispatch => {
    dispatch({
      type: UPDATE_CATEGORIES,
      payload: {
        value,
      },
    })
  }
}

export const SET_PRICE_FOR_CATEGORY = 'TICKETS/SET_PRICE_FOR_CATEGORY'

export function setPriceForCategory(id, value) {
  return dispatch => {
    dispatch({
      type: SET_PRICE_FOR_CATEGORY,
      payload: {
        id,
        value,
      },
    })
  }
}

export const CREATE_TICKET_REQUEST = 'TICKETS/CREATE_TICKET_REQUEST'
export const CREATE_TICKET_SUCCESS = 'TICKETS/CREATE_TICKET_SUCCESS'

export function createTicket(formObj) {
  return dispatch => {
    dispatch({
      types: [CREATE_TICKET_REQUEST, CREATE_TICKET_SUCCESS],
      payload: {
        request: {
          method: 'POST',
          url: `/ticket/create`,
          data: {
            ...formObj,
          },
        },
      },
    })
  }
}

export const UPDATE_TICKET_REQUEST = 'TICKETS/UPDATE_TICKET_REQUEST'
export const UPDATE_TICKET_SUCCESS = 'TICKETS/UPDATE_TICKET_SUCCESS'

export function updateTicket(id, formObj) {
  return dispatch => {
    dispatch({
      types: [UPDATE_TICKET_REQUEST, UPDATE_TICKET_SUCCESS],
      payload: {
        request: {
          method: 'POST',
          url: `/ticket/update/${id}`,
          data: {
            ...formObj,
          },
        },
      },
    })
  }
}
// TICKET FORM END

// SEANCES START
export const UPDATE_SEANCES = 'TICKETS/UPDATE_SEANCES'

export function updateSeances(seances) {
  return dispatch => {
    dispatch({
      type: UPDATE_SEANCES,
      payload: {
        seances,
      }
    })
  }
}

export const UPDATE_SEANCES_SETTINGS = 'TICKETS/UPDATE_SEANCES_SETTINGS'

export function updateSeancesSettings(seancesSettings) {
  return dispatch => {
    dispatch({
      type: UPDATE_SEANCES_SETTINGS,
      payload: {
        seancesSettings,
      }
    })
  }
}
// SEANCES END